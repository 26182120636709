import React, { useState } from "react";

export default function Faq() {
  const faqData = [
    {
      question: "Do you package Puchka , fillings & pani separately?",
      answer: "Yes, everything is separated so you can enjoy them as per your choice.",
    },
    {
      question: "What flavours do you have?",
      answer: "Please refer menu.",
    },
    {
      question: "Is there any customization available?",
      answer: "Yes, all you need to do is while ordering online you have to mention that in the additional options or direction place. Otherwise at the store you can directly ask our staff for this.",
    },
    {
      question: "Do you use mineral water?",
      answer: "Yes, we do.",
    },
    {
      question: "What’s the starting price of your Puchka & Litti?",
      answer: "In stores, it’s ₹39 & ₹89 for Puchka & Litti respectively.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };



  return (
    <div className="row-fluid-wrapper row-depth-1 row-number-3 dnd_area-row-1-background-color dnd_area-row-1-max-width-section-centering dnd-section">
      <div className="row-fluid ">
        <div
          className="span12 widget-span widget-type-cell dnd-column"
          style={{}}
          data-widget-type="cell"
          data-x={0}
          data-w={12}
        >
          <div className="row-fluid-wrapper row-depth-1 row-number-4 dnd-row">
            <div className="row-fluid ">
              <div
                className="span12 widget-span widget-type-custom_widget dnd-module"
                style={{}}
                data-widget-type="custom_widget"
                data-x={0}
                data-w={12}
              >
                <div
                  id="hs_cos_wrapper_widget_1662710617371"
                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                  style={{}}
                  data-hs-cos-general-type="widget"
                  data-hs-cos-type="module"
                >
                  <section className="frosty-accordion text-center">
                    <div className="heading-text">
                      <h2>
                        <span className="heading-type heading-color font-size-">
                          Frequently Asked Questions
                        </span>
                      </h2>
                    </div>
                    <div className="mb-40">
                      <p>
                        <span>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Suspendisse varius enim in eros elementum
                          tristique.
                        </span>
                      </p>
                    </div>
                    <div
                      className="panel-group"
                      id="accordion-widget_1662710617371"
                      role="tablist"
                      aria-multiselectable="true"
                    >
                      {faqData.map((item, index) => (
                        <div className="panel panel-default text-left p-40 mb-20 border-radius box-shadow" key={index}>
                          <div className="panel-heading" role="tab" id="heading1-widget_1662710617371">
                            <h4 className="panel-title font-size- font-weight-600 line-height-12">
                              <a
                                role="button"

                                data-toggle="collapse"
                                data-parent="#accordion-widget_1662710617371"
                                aria-expanded={activeIndex === index} // this needs to be be changed when the collapse
                                aria-controls="collapse1-widget_1662710617371"
                                className="collapsed"
                                onClick={() => handleToggle(index)}
                              >
                                {item.question}
                                <div className="icon text-center">
                                  <span
                                    id="hs_cos_wrapper_widget_1662710617371_"
                                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon"
                                    style={{}}
                                    data-hs-cos-general-type="widget"
                                    data-hs-cos-type="icon"
                                  >
                                    <svg
                                      version={1.0}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 448 512"
                                      width={20}
                                      height={20}
                                      aria-hidden="true"
                                    >
                                      <g id="chevron-down1_layer">
                                        <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z" />
                                      </g>
                                    </svg>
                                  </span>
                                </div>
                              </a>
                            </h4>
                          </div>
                          <div
                            id="collapse1-widget_1662710617371"
                            className={`panel-collapse collapse ${activeIndex === index ? 'in' : ''}`}
                            role="tabpanel"
                            aria-labelledby="heading1-widget_1662710617371"
                          >
                            <div className="panel-body">
                              <div className="content">
                                <p>
                                  <span>{item.answer}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {/* <div className="mt-30">
                      <a className="button" href="#">
                        BUTTON
                        <span
                          id="hs_cos_wrapper_widget_1662710617371_"
                          className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon"
                          style={{}}
                          data-hs-cos-general-type="widget"
                          data-hs-cos-type="icon"
                        >
                          <svg
                            version={1.0}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            width={16}
                            height={16}
                            aria-hidden="true"
                          >
                            <g id="chevron-down1_layer">
                              <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z" />
                            </g>
                          </svg>
                        </span>
                      </a>
                    </div> */}
                  </section>
                </div>
              </div>
              {/*end widget-span */}
            </div>
            {/*end row*/}
          </div>
          {/*end row-wrapper */}
        </div>
        {/*end widget-span */}
      </div>
      {/*end row*/}
    </div>
  );
}
