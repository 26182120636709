import React from "react";

export default function Articles() {
  return (
    <main id="main-content" className="body-container-wrapper">
      <div className="body-container body-container--blog-post">

        {/* article */}
        <div
          id="hs_cos_wrapper_frosty_blog_post_hero"
          className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
          style={{}}
          data-hs-cos-general-type="widget"
          data-hs-cos-type="module"
        >
          <section className="frosty-blog-post-hero-case-study">
            <div className="content-wrapper">
              <div className="max-width-1000 mr-auto ml-auto text-center">
                <ul className="list-inline tagline-wrap mb-15">
                  <li className="list-inline-item">
                    <a
                      className="tagline secondary-color font-size-18"
                      href="tag/articles.html"
                    >
                      Articles
                    </a>
                  </li>
                </ul>
                <div className="heading-text">
                  <h1 className="heading-type heading-color font-size-48">
                    <span
                      id="hs_cos_wrapper_name"
                      className="hs_cos_wrapper hs_cos_wrapper_meta_field hs_cos_wrapper_type_text"
                      style={{}}
                      data-hs-cos-general-type="meta_field"
                      data-hs-cos-type="text"
                    >
                      The secret trick to prepare a perfect hamburger
                    </span>
                  </h1>
                </div>

                <img
                  src="https://info.juicetactics.com/hubfs/blog-post-01.jpeg"
                  className="border-radius featured-image"
                  alt
                />

                <ul className="list-inline mt-50">
                  <li className="list-inline-item font-weight-600 date font-size-14">
                    September 1, 2022
                  </li>
                </ul>

                <div className="blog-author">
                  <div className="blog-author-image blog-author-col">
                    <img
                      src="https://info.juicetactics.com/hubfs/pexels-max-griss-7865794.jpg"
                      alt="Frosty"
                    />
                  </div>
                  <div className="blog-author-name blog-author-col">
                    <a href="author/frosty.html" rel="author">
                      Frosty
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

      

        {/* subsribe newsletter
        <div
          id="hs_cos_wrapper_frosty_blog_post_subscribe_form"
          className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
          style={{}}
          data-hs-cos-general-type="widget"
          data-hs-cos-type="module"
        >
          <section className="frosty-blog-post-subscribe-form  ">
            <div className="content-wrapper">
              <div className="subscribe-form-wrap px-40 py-40 border-radius box-shadow mr-auto ml-auto">
                <div className="heading-text">
                  <h3>
                    <span className="heading-type heading-color font-size-">
                      Subscribe to our newsletter today!
                    </span>
                  </h3>
                </div>
                <div className="mt-0">
                  <span
                    id="hs_cos_wrapper_frosty_blog_post_subscribe_form_"
                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_form"
                    style={{}}
                    data-hs-cos-general-type="widget"
                    data-hs-cos-type="form"
                  >
                    <div id="hs_form_target_frosty_blog_post_subscribe_form_" />
                  </span>
                </div>
              </div>
            </div>
          </section>
        </div> */}
      </div>
    </main>
  );
}
