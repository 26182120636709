import React from "react";
import { Link } from "react-router-dom";
import BounceLoader from "react-spinners/BounceLoader";

export default function OurMenu({ categories }) {
  const descriptions = {
    "Puchka": "Burst of flavor with every bite, whether it's with Khatta or Mitha pani, Spice Currant or Sweet Chocolates, Tangy Masala or Dahi Chutney.",
    "Beverage ": "Refresh with handcrafted krushers and soft drinks, a delightful array to quench your thirst and elevate your beverage experience.",
    "Chaat": "Kolkata evenings come alive with Alu Kabli and Churmur, laughter, and Wah! Chaatpata gossip over savory delights with friends and family",
    "Litti": " Discover Bihar's rustic charm in Kolkata, savor perfectly roasted Litti dipped in desi ghee, paired with authentic potato or brinjal chokha.",
    "Dahi vada ": "Delight in the unique blend of creamy indulgence and zesty flavors with Wah! Dahi Vada—a sweet and tangy culinary experience.",
    "Combos ": "Savor harmonious flavors in Wah! combos, like zesty panipuri with krushers or comforting Litti chokha with dahi vada taste symphony awaits."
  };

  const mappedData = categories.map(name => ({
    name: name,
    description: descriptions[name]
  }));

 

  return (
    <div className="row-fluid-wrapper row-depth-1 row-number-6 dnd-section">
      <div className="row-fluid ">
        <div
          className="span12 widget-span widget-type-cell dnd-column"
          style={{}}
          data-widget-type="cell"
          data-x={0}
          data-w={12}
        >
          <div className="row-fluid-wrapper row-depth-1 row-number-7 dnd-row">
            <div className="row-fluid ">
              <div
                className="span12 widget-span widget-type-custom_widget dnd-module"
                style={{}}
                data-widget-type="custom_widget"
                data-x={0}
                data-w={12}
              >
                <div
                  id="hs_cos_wrapper_widget_1662016633832"
                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                  style={{}}
                  data-hs-cos-general-type="widget"
                  data-hs-cos-type="module"
                >
                  <section className="frosty-card-2 text-center">

                    <div className="heading-text">
                      <h2>
                        <span className="heading-type heading-color font-size-">
                          Explore our menu
                        </span>
                      </h2>
                    </div>
                    <div className="mb-30">
                      <p>
                        <span>
                          Satisfy your cravings through the tastiest flavourful Puchka & authentic Litti chokha, take a look over Wah! special combos.
                        </span>
                      </p>
                    </div>
                    <div className="flex">
                      {mappedData.length > 0 ? (
                        mappedData.map((category, index) => (
                          <div className="col" key={index}>
                            <div className="card border-radius box-shadow py-50 text-center" style={{ paddingLeft: '20px', paddingRight: '20px', height: '100%' }} >
                              <div>
                                <h3 className="h4 card-title font-size-30">
                                  {category.name}
                                </h3>
                              </div>
                              <div className="card-text">
                                <p style={{ fontSize: '16px' }}>
                                  {category.description}
                                </p>
                              </div>
                              <div className="mt-30 button-full-width">
                                <Link className="button-outline" to={`/menu?category=${encodeURIComponent(category.name)}`} >
                                  Explore
                                  <span
                                    id="hs_cos_wrapper_widget_1662016633832_"
                                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon"
                                    style={{}}
                                    data-hs-cos-general-type="widget"
                                    data-hs-cos-type="icon"
                                  >
                                    <svg
                                      version={1.0}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 256 512"
                                      width={16}
                                      height={16}
                                      aria-hidden="true"
                                    >
                                      <g id="angle-right2_layer">
                                        <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z" />
                                      </g>
                                    </svg>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                          <BounceLoader color="#E85900" />
                        </div>
                      )}
                    </div>

                    <div className="text-center mt-40">
                      <Link className="button" to="/menu">
                      Order Now
                      </Link>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  );
}
