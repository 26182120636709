import React from "react";

export default function Values() {
  return (
    <div className="row-fluid-wrapper row-depth-1 row-number-5 dnd-section">
      <div className="row-fluid ">
        <div
          className="span12 widget-span widget-type-cell dnd-column"
          style={{}}
          data-widget-type="cell"
          data-x={0}
          data-w={12}
        >
          <div className="row-fluid-wrapper row-depth-1 row-number-6 dnd-row">
            <div className="row-fluid ">
              <div
                className="span12 widget-span widget-type-custom_widget dnd-module"
                style={{}}
                data-widget-type="custom_widget"
                data-x={0}
                data-w={12}
              >
                <div
                  id="hs_cos_wrapper_widget_1662038607876"
                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                  style={{}}
                  data-hs-cos-general-type="widget"
                  data-hs-cos-type="module"
                >
                  <section className="frosty-card-1 text-center">
                    {/* <div className="tagline-wrap mb-15">
                      <div className="tagline secondary-color">
                        The values that drive
                      </div>
                    </div> */}
                    <div className="heading-text">
                      <h2>
                        <span className="heading-type heading-color font-size-">
                        Everything we do
                        </span>
                      </h2>
                    </div>
                    <div className="flex">
                      <div className="col">
                        <div className="border-radius card py-20 px-20 text-center">
                          <div className="card-icon mb-20">
                            <span
                              id="hs_cos_wrapper_widget_1662038607876_"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon"
                              style={{}}
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="icon"
                            >
                              <svg
                                version={1.0}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                width={40}
                                height={40}
                                aria-hidden="true"
                              >
                                <g id="Mug Hot1_layer">
                                  <path d="M127.1 146.5c1.3 7.7 8 13.5 16 13.5h16.5c9.8 0 17.6-8.5 16.3-18-3.8-28.2-16.4-54.2-36.6-74.7-14.4-14.7-23.6-33.3-26.4-53.5C111.8 5.9 105 0 96.8 0H80.4C70.6 0 63 8.5 64.1 18c3.9 31.9 18 61.3 40.6 84.4 12 12.2 19.7 27.5 22.4 44.1zm112 0c1.3 7.7 8 13.5 16 13.5h16.5c9.8 0 17.6-8.5 16.3-18-3.8-28.2-16.4-54.2-36.6-74.7-14.4-14.7-23.6-33.3-26.4-53.5C223.8 5.9 217 0 208.8 0h-16.4c-9.8 0-17.5 8.5-16.3 18 3.9 31.9 18 61.3 40.6 84.4 12 12.2 19.7 27.5 22.4 44.1zM400 192H32c-17.7 0-32 14.3-32 32v192c0 53 43 96 96 96h192c53 0 96-43 96-96h16c61.8 0 112-50.2 112-112s-50.2-112-112-112zm0 160h-16v-96h16c26.5 0 48 21.5 48 48s-21.5 48-48 48z" />
                                </g>
                              </svg>
                            </span>
                          </div>
                          <div>
                            <h3 className="h4 card-title mb-10 font-size-30">
                              Flavor
                            </h3>
                          </div>
                          <div className="card-text">
                            <div data-w-id="8b3b9577-a00a-3c29-339c-ec3bb90fb28c">
                              <p>
                              We provide the tastiest & most unique flavours & varieties of puchka & litti chokha in the
city.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="border-radius card py-20 px-20 text-center">
                          <div className="card-icon mb-20">
                            <span
                              id="hs_cos_wrapper_widget_1662038607876_"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon"
                              style={{}}
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="icon"
                            >
                              <svg
                                version={1.0}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                width={40}
                                height={40}
                                aria-hidden="true"
                              >
                                <g id="Heart2_layer">
                                  <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                                </g>
                              </svg>
                            </span>
                          </div>
                          <div>
                            <h3 className="h4 card-title mb-10 font-size-30">
                              {/* Love &amp; Passion */}
                              Customisation
                            </h3>
                          </div>
                          <div className="card-text">
                            <p>
                              <span>
                              We customise puchkas & littis as per our customers’ direction & requirements.
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="border-radius card py-20 px-20 text-center">
                          <div className="card-icon mb-20">
                            <span
                              id="hs_cos_wrapper_widget_1662038607876_"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon"
                              style={{}}
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="icon"
                            >
                              <svg
                                version={1.0}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                width={40}
                                height={40}
                                aria-hidden="true"
                              >
                                <g id="Hot Pepper3_layer">
                                  <path d="M330.67 263.12V173.4l-52.75-24.22C219.44 218.76 197.58 400 56 400a56 56 0 0 0 0 112c212.64 0 370.65-122.87 419.18-210.34l-37.05-38.54zm131.09-128.37C493.92 74.91 477.18 26.48 458.62 3a8 8 0 0 0-11.93-.59l-22.9 23a8.06 8.06 0 0 0-.89 10.23c6.86 10.36 17.05 35.1-1.4 72.32A142.85 142.85 0 0 0 364.34 96c-28 0-54 8.54-76.34 22.59l74.67 34.29v78.24h89.09L506.44 288c3.26-12.62 5.56-25.63 5.56-39.31a154 154 0 0 0-50.24-113.94z" />
                                </g>
                              </svg>
                            </span>
                          </div>
                          <div>
                            <h3 className="h4 card-title mb-10 font-size-30">
                            Health
                            </h3>
                          </div>
                          <div className="card-text">
                            <p>
                              <span>
                              We only use aata while making puchkas & for a healthier option we’ve introduced millet
based puchka.
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="border-radius card py-20 px-20 text-center">
                          <div className="card-icon mb-20">
                            <span
                              id="hs_cos_wrapper_widget_1662038607876_"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon"
                              style={{}}
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="icon"
                            >
                              <svg
                                version={1.0}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                width={40}
                                height={40}
                                aria-hidden="true"
                              >
                                <g id="Alternate User4_layer">
                                  <path d="M256 288c79.5 0 144-64.5 144-144S335.5 0 256 0 112 64.5 112 144s64.5 144 144 144zm128 32h-55.1c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16H128C57.3 320 0 377.3 0 448v16c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48v-16c0-70.7-57.3-128-128-128z" />
                                </g>
                              </svg>
                            </span>
                          </div>
                          <div>
                            <h3 className="h4 card-title mb-10 font-size-30">
                            Hygiene
                            </h3>
                          </div>
                          <div className="card-text">
                            <p>
                              <span>
                              We never compromise with hygiene and that’s why every standard guidelines are strictly followed such as fresh raw materials, proper cleaning, clean utensils, gloves etc.
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="border-radius card py-20 px-20 text-center">
                          <div className="card-icon mb-20">
                            <span
                              id="hs_cos_wrapper_widget_1662038607876_"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon"
                              style={{}}
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="icon"
                            >
                              <svg
                                version={1.0}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 640 512"
                                width={40}
                                height={40}
                                aria-hidden="true"
                              >
                                <g id="Users5_layer">
                                  <path d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z" />
                                </g>
                              </svg>
                            </span>
                          </div>
                          <div>
                            <h3 className="h4 card-title mb-10 font-size-30">
                            Offline & online service 
                            </h3>
                          </div>
                          <div className="card-text">
                            <p>
                              <span>
                              We have customer friendly outlets in 4 different locations in Kolkata where they can spend their special moments, events even work at peace. We also deliver via Swiggy &
Zomato with proper packaging.

                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="border-radius card py-20 px-20 text-center">
                          <div className="card-icon mb-20">
                            <span
                              id="hs_cos_wrapper_widget_1662038607876_"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon"
                              style={{}}
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="icon"
                            >
                              <svg
                                version={1.0}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                width={40}
                                height={40}
                                aria-hidden="true"
                              >
                                <g id="Check6_layer">
                                  <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
                                </g>
                              </svg>
                            </span>
                          </div>
                          <div>
                            <h3 className="h4 card-title mb-10 font-size-30">
                            Parties/Event & sponsors
                            </h3>
                          </div>
                          <div className="card-text">
                            <p>
                              <span>
                              One can pre-book us on their parties or occasions, for that please connect with us from the contacts given. Sometimes we also sponsor various colleges or events in Kolkata.
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="mt-30">
                      <a className="button" href="#">
                        Check our menu
                      </a>
                    </div> */}
                  </section>
                </div>
              </div>
              {/*end widget-span */}
            </div>
            {/*end row*/}
          </div>
          {/*end row-wrapper */}
        </div>
        {/*end widget-span */}
      </div>
      {/*end row*/}
    </div>
  );
}
