import React, { useEffect, useState } from "react";

export default function FavApp() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  return (
    <div className="row-fluid-wrapper row-depth-1 row-number-3 dnd_area-row-1-background-color dnd-section">
      <div className="row-fluid ">
        <div
          className="span12 widget-span widget-type-cell dnd-column"
          style={{}}
          data-widget-type="cell"
          data-x={0}
          data-w={12}
        >
          <div className="row-fluid-wrapper row-depth-1 row-number-4 dnd-row">
            <div className="row-fluid ">
              <div
                className="span12 widget-span widget-type-custom_widget dnd-module"
                style={{}}
                data-widget-type="custom_widget"
                data-x={0}
                data-w={12}
              >
                <div
                  id="hs_cos_wrapper_widget_1662016548486"
                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text"
                  style={{}}
                  data-hs-cos-general-type="widget"
                  data-hs-cos-type="module"
                >
                  <span
                    id="hs_cos_wrapper_widget_1662016548486_"
                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text"
                    style={{}}
                    data-hs-cos-general-type="widget"
                    data-hs-cos-type="text"
                  >
                    Order from your sub-brand today!
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row-fluid-wrapper row-depth-1 row-number-5 dnd-row">
            <div className="row-fluid ">
              <div
                className="span12 widget-span widget-type-custom_widget dnd-module"
                style={{}}
                data-widget-type="custom_widget"
                data-x={0}
                data-w={12}
              >
                <div
                  id="hs_cos_wrapper_widget_1662015066936"
                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                  data-hs-cos-general-type="widget"
                  data-hs-cos-type="module"
                >
                  <section className="frosty-logo-slider">
                    <div
                      className="customer-logos"
                      id="customer-logos-widget_1662015066936"
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: isMobile ? "row" : "row",

                      }}
                    >
                      <div className="slide" style={{padding: isMobile ? '0px 13px' : '0px 15px'}}>
                        <img
                          className
                          src="https://res.cloudinary.com/diyncva2v/image/upload/v1711211772/d3uqto0fvtcpoan2fyha.png"
                          alt="wah! puchka"
                          style={{
                           height: isMobile ? '150px'  : '180px',
                           width: isMobile ? '160px'  : '180px',
                           borderRadius: '15px'
                          }}
                        />
                      </div>
                      <div className="slide" style={{padding: isMobile ? '0px 13px' : '0px 15px'}}>
                        <img
                          className
                          src="https://res.cloudinary.com/diyncva2v/image/upload/v1711211772/k2ydpqrox4o5ddi0n7xk.png"
                          alt="wah! Litti"
                          style={{
                            height: isMobile ? '150px'  : '180px',
                            width: isMobile ? '160px'  : '180px',
                            borderRadius: '15px'
                           }}
                        />
                      </div>
                      <div className="slide" style={{padding: isMobile ? '0px 13px' : '0px 15px'}}>
                        <img
                          className
                          src="https://res.cloudinary.com/diyncva2v/image/upload/v1711211772/zwli2agoycvrnc6uanz8.png"
                          alt="wah! Jhal Muri"
                          style={{
                            height: isMobile ? '150px'  : '180px',
                            width: isMobile ? '160px'  : '180px',
                            borderRadius: '15px'
                           }}
                        />
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              {/*end widget-span */}
            </div>
            {/*end row*/}
          </div>
          {/*end row-wrapper */}
        </div>
        {/*end widget-span */}
      </div>
      {/*end row*/}
    </div>
  );
}
