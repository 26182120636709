import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

export default function OurMenu({ categories, filterProductsByCategory }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const urlCategory = queryParams.get('category');

  const [selectedCategory, setSelectedCategory] = useState('All');

  useEffect(() => {
    if (urlCategory) {
      // If 'category' is present in the URL, set the selected category and filter products
      setSelectedCategory(urlCategory);
      filterProductsByCategory(urlCategory);
    }
  }, [urlCategory]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    filterProductsByCategory(category);
  };


  return (
    <div className="row-fluid-wrapper row-depth-1 row-number-1 dnd-section">
      <div className="row-fluid ">
        <div
          className="span12 widget-span widget-type-cell dnd-column"
          style={{}}
          data-widget-type="cell"
          data-x={0}
          data-w={12}
        >
          <div className="row-fluid-wrapper row-depth-1 row-number-2 dnd-row">
            <div className="row-fluid ">
              <div
                className="span12 widget-span widget-type-custom_widget dnd-module"
                style={{}}
                data-widget-type="custom_widget"
                data-x={0}
                data-w={12}
              >
                <div
                  id="hs_cos_wrapper_widget_1663068668186"
                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                  style={{}}
                  data-hs-cos-general-type="widget"
                  data-hs-cos-type="module"
                >
                  <section className="frosty-gallery-filter text-center">
                    <div className="heading-text">
                      <h1>
                        <span className="heading-type heading-color font-size-">
                          Explore our menu
                        </span>
                      </h1>
                    </div>
                    <div className="mb-30">
                      <p>
                        <span>
                          Satisfy your cravings through the tastiest flavourful Puchka & authentic litti chokha, take a look over Wah! special combos.
                        </span>
                      </p>
                    </div>
                    <div id="users-widget_1663068668186">
                      <div className="filter-group mb-30">
                        <div className="form-group ">
                          <div className="radio-inline radio-inline-widget_1663068668186">
                            <label className={`${selectedCategory === 'All' ? 'active' : ''}  button-outline`} onClick={() => { handleCategoryChange('All') }}>
                              <input
                                className="filter-all filter-all-widget_1663068668186"
                                type="radio"
                                defaultValue="all"
                                name="gallery"
                                id="gallery-all"
                                defaultChecked
                              />{" "}
                              All
                            </label>
                          </div>
                          {categories ?
                            categories.map((category, index) => ( // Added parentheses around the map function parameters
                              <div className="radio-inline radio-inline-widget_1663068668186" key={index}>
                                <label className={`${selectedCategory === category ? 'active' : ''}  button-outline`} onClick={() => { handleCategoryChange(category) }}>
                                  <input
                                    className="filter filter-widget_1663068668186"
                                    type="radio"
                                    defaultValue={category}
                                    name="gallery"
                                    id={`gallery-${category}`}
                                  />{" "}
                                  {category}
                                </label>
                              </div>
                            ))
                            : null
                          }


                        </div>
                      </div>

                    </div>

                  </section>
                </div>
              </div>
              {/*end widget-span */}
            </div>
            {/*end row*/}
          </div>
          {/*end row-wrapper */}
        </div>
        {/*end widget-span */}
      </div>
      {/*end row*/}
    </div>
  );
}
