import React from "react";
import Header from "../Layout/Header";
import Faq from "../components/FAQ/Faq";
import Footer from "../Layout/Footer";

export default function FAQ() {
  return (
    <div className="body-wrapper   hs-content-id-84411850421 hs-site-page page hs-content-path-frosty-contact hs-content-name-frosty-contact  ">
      <Header />
      <main id="main-content" className="body-container-wrapper">
        <div className="container-fluid body-container body-container--contact">
          <div className="row-fluid-wrapper">
            <div className="row-fluid">
              <div
                className="span12 widget-span widget-type-cell "
                style={{}}
                data-widget-type="cell"
                data-x={0}
                data-w={12}
              >
                <Faq />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
