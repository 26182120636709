import React from "react";
import Header from "../Layout/Header";
import Hero from "../components/AboutUs/Hero";
import FavApp from "../components/AboutUs/FavApp";
import Values from "../components/AboutUs/Values";
import Story from "../components/AboutUs/Story";
import Footer from "../Layout/Footer";

export default function About() {
  return (
    <div className="body-wrapper   hs-content-id-83670317458 hs-site-page page hs-content-path-frosty-about hs-content-name-frosty-about  ">
      <Header />
      <main id="main-content" className="body-container-wrapper">
        <div className="container-fluid body-container body-container--home container-fluid-custom">
          <div className="row-fluid-wrapper">
            <div className="row-fluid">
              <div
                className="span12 widget-span widget-type-cell "
                style={{}}
                data-widget-type="cell"
                data-x={0}
                data-w={12}
              >
                <Hero />
                <Story />
                <FavApp />
                <Values />
                
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
