import React from 'react'

export default function PerfectHamburger() {
  return (
    <main id="main-content" className="body-container-wrapper">
        <div class="body-container body-container--blog-post">
             {/* What does the perfect hamburger taste like? */}
        <div className="content-wrapper">
          <article className="blog-post">
            <div className="blog-post__body">
              <span
                id="hs_cos_wrapper_post_body"
                className="hs_cos_wrapper hs_cos_wrapper_meta_field hs_cos_wrapper_type_rich_text"
                style={{}}
                data-hs-cos-general-type="meta_field"
                data-hs-cos-type="rich_text"
              >
                <h2>What does the perfect hamburger taste like?</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  lobortis arcu enim urna adipiscing praesent velit viverra sit
                  semper lorem eu cursus vel hendrerit elementum morbi curabitur
                  etiam nibh justo, lorem aliquet donec sed sit mi dignissim at
                  ante massa mattis.
                </p>
                {/*more*/}
                <ol role="list" start>
                  <li>
                    Neque sodales ut etiam sit amet nisl purus non tellus orci
                    ac auctor
                  </li>
                  <li>
                    Adipiscing elit ut aliquam purus sit amet viverra
                    suspendisse potent i
                  </li>
                  <li>
                    Mauris commodo quis imperdiet massa tincidunt nunc pulvinar
                  </li>
                  <li>
                    Adipiscing elit ut aliquam purus sit amet viverra
                    suspendisse potenti
                  </li>
                </ol>
                <h3>Why are ingredients so important?</h3>
                <p>
                  Vitae congue eu consequat ac felis placerat<span>&nbsp;</span>
                  <strong>vestibulum lectus</strong>
                  <span>&nbsp;</span>mauris ultrices cursus sit amet dictum sit
                  amet justo donec enim diam porttitor lacus luctus accumsan
                  tortor posuere praesent tristique magna sit amet purus gravida
                  quis blandit turpis.
                </p>
                <p>
                  <img
                    src="https://info.juicetactics.com/hs-fs/hubfs/blog-post-01.jpeg?width=1600&name=blog-post-01.jpeg"
                    alt="blog-post-01"
                    width={1600}
                    loading="lazy"
                    style={{ width: 1600 }}
                    srcSet="https://info.juicetactics.com/hs-fs/hubfs/blog-post-01.jpeg?width=800&name=blog-post-01.jpeg 800w, https://info.juicetactics.com/hs-fs/hubfs/blog-post-01.jpeg?width=1600&name=blog-post-01.jpeg 1600w, https://info.juicetactics.com/hs-fs/hubfs/blog-post-01.jpeg?width=2400&name=blog-post-01.jpeg 2400w, https://info.juicetactics.com/hs-fs/hubfs/blog-post-01.jpeg?width=3200&name=blog-post-01.jpeg 3200w, https://info.juicetactics.com/hs-fs/hubfs/blog-post-01.jpeg?width=4000&name=blog-post-01.jpeg 4000w, https://info.juicetactics.com/hs-fs/hubfs/blog-post-01.jpeg?width=4800&name=blog-post-01.jpeg 4800w"
                    sizes="(max-width: 1600px) 100vw, 1600px"
                  />
                </p>
                <h4>What burger recipes exist you can follow?</h4>
                <p>
                  At risus viverra adipiscing at in tellus integer feugiat nisl
                  pretium fusce id velit ut tortor sagittis orci a scelerisque
                  purus semper eget at lectus urna duis convallis. porta nibh
                  venenatis cras sed felis eget neque laoreet suspendisse
                  interdum consectetur libero id faucibus nisl donec pretium
                  vulputate sapien nec sagittis aliquam nunc lobortis mattis
                  aliquam faucibus purus in.
                </p>
                <ul role="list">
                  <li>
                    Neque sodales ut etiam sit amet nisl purus non tellus orci
                    ac auctor
                  </li>
                  <li>
                    Adipiscing elit ut aliquam purus sit amet viverra
                    suspendisse potenti
                  </li>
                  <li>
                    Mauris commodo quis imperdiet massa tincidunt nunc pulvinar
                  </li>
                  <li>
                    Adipiscing elit ut aliquam purus sit amet viverra
                    suspendisse potenti
                  </li>
                </ul>
                <h4>How to ensure the burger is correctly baked?</h4>
                <p>
                  Nisi quis eleifend quam adipiscing vitae aliquet bibendum enim
                  facilisis<span>&nbsp;</span>
                  <strong>gravida neque</strong>. Velit euismod in pellentesque
                  massa placerat volutpat lacus laoreet non curabitur gravida
                  odio aenean sed adipiscing diam donec adipiscing tristique
                  risus. amet est placerat in egestas erat imperdiet sed euismod
                  nisi.
                </p>
                <h4>Where had you had the best breakfast of your life?</h4>
                <p>
                  Eget lorem dolor sed viverra ipsum nunc aliquet bibendum felis
                  donec et odio pellentesque diam volutpat commodo sed egestas
                  aliquam sem fringilla ut morbi tincidunt augue interdum velit
                  euismod eu tincidunt tortor aliquam nulla facilisi aenean sed
                  adipiscing diam donec adipiscing ut lectus arcu bibendum at
                  varius vel pharetra nibh venenatis cras sed felis eget.
                </p>
              </span>
            </div>
          </article>
          <div className="blog-comments">
            <div
              id="hs_cos_wrapper_blog_comments"
              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-blog_comments"
              style={{}}
              data-hs-cos-general-type="widget"
              data-hs-cos-type="module"
            >
              <span
                id="hs_cos_wrapper_blog_comments_blog_comments"
                className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_blog_comments"
                style={{}}
                data-hs-cos-general-type="widget"
                data-hs-cos-type="blog_comments"
              >
                <div className="section post-footer">
                  <div id="comments-listing" className="new-comments" />
                  <div id="hs_form_target_b611534f-95ed-4478-a690-08459312300a" />
                </div>
              </span>
            </div>
          </div>
        </div>
        </div>
    </main>
  )
}
