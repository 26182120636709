import React from "react";

export default function Contact() {
  return (
    <div className="row-fluid-wrapper row-depth-1 row-number-4 dnd_area-row-2-padding dnd-section">
      <div className="row-fluid ">
        <div
          className="span12 widget-span widget-type-cell dnd-column"
          style={{}}
          data-widget-type="cell"
          data-x={0}
          data-w={12}
        >
          <div className="row-fluid-wrapper row-depth-1 row-number-5 dnd-row">
            <div className="row-fluid ">
              <div
                className="span12 widget-span widget-type-custom_widget dnd-module"
                style={{}}
                data-widget-type="custom_widget"
                data-x={0}
                data-w={12}
              >
                <div
                  id="hs_cos_wrapper_widget_1662036480516"
                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                  style={{}}
                  data-hs-cos-general-type="widget"
                  data-hs-cos-type="module"
                >
                  <section className="frosty-cta-1 ">
                    <div className="flex flex--right-button">
                      <div className="col text-phone-left">
                        <div className="border-radius description-content-wrap">
                          <div className="description-content-text mb-30">
                            <div>
                              <h3>Come and visit us in Los Angeles, CA</h3>
                            </div>
                          </div>
                          <div>
                            <a className="button" href="#">
                              Contact Us
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              {/*end widget-span */}
            </div>
            {/*end row*/}
          </div>
          {/*end row-wrapper */}
        </div>
        {/*end widget-span */}
      </div>
      {/*end row*/}
    </div>
  );
}
