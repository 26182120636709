import React, { useState, useEffect} from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css"; // Import the toast styles
import BounceLoader from "react-spinners/BounceLoader";

export default function GetInTouch() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    message: ""
  });

  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (event) => {
    setIsButtonVisible(false);
    event.preventDefault();
    const coolSubjectText = `Hey Wah! Foods, Check this out!`;

    try {
      const response = await axios.post("https://wahfoods.onrender.com/send-email", {
        emailUser: "ashish.worksspace@gmail.com",
        emailPass: "samrxqkqstnkfaok",
        to: "trojan2277@gmail.com",
        subject: coolSubjectText,
        text: `Name: ${formData.name}\nEmail: ${formData.email}\nMobile Number: ${formData.mobileNumber}\nMessage: ${formData.message}`
      });

      // Show a toast message when the email is sent successfully
      toast.success("Email sent successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // transition: Bounce,
        });

      console.log("Email sent successfully!", response.data);
      setIsButtonVisible(true);

      setFormData({
        name: "",
        email: "",
        mobileNumber: "",
        message: ""
      });
    } catch (error) {
      console.error("Error sending email:", error);
      // Show a toast message for any errors
      setIsButtonVisible(true);

      toast.error("Error sending email. Please try again later.");
    }
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);
  return (
    
    <div className="row-fluid-wrapper row-depth-1 row-number-1 dnd-section">
      <div className="row-fluid ">
        <div
          className="span12 widget-span widget-type-cell dnd-column"
          style={{}}
          data-widget-type="cell"
          data-x={0}
          data-w={12}
        >
          <div className="row-fluid-wrapper row-depth-1 row-number-2 dnd-row">
            <div className="row-fluid ">
              <div
                className="span12 widget-span widget-type-custom_widget dnd-module"
                style={{}}
                data-widget-type="custom_widget"
                data-x={0}
                data-w={12}
              >
                <div
                  id="hs_cos_wrapper_dnd_area-module-4"
                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                  style={{}}
                  data-hs-cos-general-type="widget"
                  data-hs-cos-type="module"
                >
                  <section className="frosty-contact-us">
                    <div className="flex flex--left-form">
                      <div className="col text-phone-left">
                        <div className="description-content-wrapper ">
                          <div className="tagline-wrap mb-15">
                            <div className="tagline secondary-color">
                              Let’s get connected
                            </div>
                          </div>
                          <div className="heading-text">
                            <h1>
                              <span className="heading-type heading-color font-size-">
                                Contact Us
                              </span>
                            </h1>
                          </div>
                          <div className="description-content-text">
                            <p>
                              Team Wah! would love to assist you. Contact us regarding all your queries & doubts. Our team will get back to you as soon as possible.
                            </p>
                          </div>
                          <div className="description-contact-block mt-20">
                            <div className="contact-icon">
                              <img
                                src="http://484997.fs1.hubspotusercontent-na1.net/hubfs/484997/raw_assets/public/frosty/images/building.svg"
                                alt="Address"
                                width={24}
                                height={24}
                              />
                            </div>
                            <div className="contact-text">
                              FD-25, Easy Kolkata Township, <br />
                              Rajdanga Main Road,
                              <br /> Kolkata 700107
                            </div>
                          </div>
                          <div className="description-contact-block mt-20">
                            <div className="contact-icon">
                              <img
                                src="http://484997.fs1.hubspotusercontent-na1.net/hubfs/484997/raw_assets/public/frosty/images/envelope.svg"
                                alt="Address"
                                width={24}
                                height={24}
                              />
                            </div>
                            <div className="contact-text">
                              <a href="mailto:unifooddelights@gmail.com">
                              unifooddelights@gmail.com
                              </a>
                            </div>
                          </div>
                          <div className="description-contact-block mt-20">
                            <div className="contact-icon">
                              <img
                                src="http://484997.fs1.hubspotusercontent-na1.net/hubfs/484997/raw_assets/public/frosty/images/phone.svg"
                                alt="Address"
                                width={24}
                                height={24}
                              />
                            </div>
                            <div className="contact-text">
                              <a href="tel:+913335676295">+91 33356 76295</a>
                              <br />
                              <a href="tel:+917980210482">+91 79802 10482</a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col text-left">
                        <div className="border-radius form" id="form">
                          <div
                            style={{
                              backgroundColor: "#FFF9F3",
                              width: "100%",
                              padding: isMobile ? '' : '20px',
                              borderRadius: "10px",
                            }}
                          >
                             <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", backgroundColor: "#FFF9F3", padding: isMobile ? '' : "35px" }}>
                              {/* Form Fields */}
                              <label style={{ marginBottom: "10px" }}>
                                Name:
                                <input
                                  type="text"
                                  name="name"
                                  value={formData.name}
                                  onChange={handleInputChange}
                                  style={{ marginBottom: "10px", padding: "15px" }}
                                />
                              </label>
                              <label style={{ marginBottom: "10px" }}>
                                Email:
                                <input
                                  type="email"
                                  name="email"
                                  value={formData.email}
                                  onChange={handleInputChange}
                                  style={{ marginBottom: "10px", padding: "15px" }}
                                />
                              </label>
                              <label style={{ marginBottom: "10px" }}>
                                Mobile Number:
                                <input
                                  type="tel"
                                  name="mobileNumber"
                                  value={formData.mobileNumber}
                                  onChange={handleInputChange}
                                  style={{ marginBottom: "10px", padding: "15px" }}
                                />
                              </label>
                              <label style={{ marginBottom: "10px" }}>
                                Message:
                                <textarea
                                  name="message"
                                  value={formData.message}
                                  onChange={handleInputChange}
                                  style={{ marginBottom: "10px", padding: "15px", minHeight: "100px" }}
                                />
                              </label>
                              {/* Submit Button */}
                              <button
                                type="submit"
                                style={{ padding: "20px", backgroundColor: "#e85900c7", width: "60%", color: "white", border: "none", cursor: "pointer", borderRadius: "50px" }}
                              >
                                {isButtonVisible ? "Submit" :  <BounceLoader color="#ffff"   size={25}/>}
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              {/*end widget-span */}
            </div>
            {/*end row*/}
          </div>
          {/*end row-wrapper */}
        </div>
        {/*end widget-span */}
      </div>
      {/*end row*/}
      <ToastContainer />
    </div>
  );
}
