import React from "react";
import { Link } from "react-router-dom";

export default function Hero() {
  return (
    <div className="row-fluid-wrapper row-depth-1 row-number-1 dnd-section">
      <div className="row-fluid ">
        <div
          className="span12 widget-span widget-type-cell dnd-column"
          style={{}}
          data-widget-type="cell"
          data-x={0}
          data-w={12}
        >
          <div className="row-fluid-wrapper row-depth-1 row-number-2 dnd-row">
            <div className="row-fluid ">
              <div
                className="span12 widget-span widget-type-custom_widget dnd-module"
                style={{}}
                data-widget-type="custom_widget"
                data-x={0}
                data-w={12}
              >
                <div
                  id="hs_cos_wrapper_widget_1661927869387"
                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                  style={{}}
                  data-hs-cos-general-type="widget"
                  data-hs-cos-type="module"
                >
                  <section className="frosty-hero-1 ">
                    <div className="flex flex--right-image">
                      <div className="col">
                        <img
                          className="img-block border-radius"
                          src="https://res.cloudinary.com/diyncva2v/image/upload/v1710618938/axuyazyp6j4eye3yiqog.jpg"
                          alt="WahFood"
                          loading="lazy"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      </div>
                      <div className="col">
                        {/* <div className="tagline-wrap mb-15">
                          <div className="tagline secondary-color">
                            About Us
                          </div>
                        </div> */}
                        <div className="heading-text">
                          <h1>
                            <span className="heading-type heading-color font-size-0">
                              Wah! Story
                            </span>
                          </h1>
                        </div>
                        <div className="mb-40">
                          <p>
                            Whenever someone says about foods of Kolkata, the first thing comes on your mind
                            is Puchka / Panipuri / Golgappa. Nothing can be more popular & catchy.
                            So the first time when I came to kolkata, like others, I also got addicted to puchka
                            as my everyday evening snack. <br /> <br /> Like the mouthwatering taste that one can hardly resist.

                            But this couldn’t be possible for long, di erent health issues raised due to the unhygienic &
                            poor materials they use in making puchka. So should I have to give up on my favourite snack now? <br /> <br />

                            Then I realised if there is a solution for everything by brands like pizza, momos, burgers, noodles
                            etc so why not to have puchka under brand hygienically keeping the same taste & almost a ordable!
                            <br /> <br /> That’s why we launched Wah! Puchka from Ruby, in the city of joy.

                            I’m from Bihar, now you must be thinking of Litti Chokha right ?? Yes, I had to maintain the authenticity
                            of my own birthplace to wherever I go or live, then eventually Wah! Litti happened to live the vibes of
                            Bihar’s kitchen to the people of kolkata.
                          </p>
                        </div>
                        <div className="button-wrap">
                          <div className="button-1 button-col">
                            <Link className="button" to="/contact">
                              Contact Us
                            </Link>
                          </div>
                          <div className="button-2 button-col">
                            <Link className="button-outline" to="/menu">
                              Order Online
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
