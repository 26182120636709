import React from "react";

export default function Subscribe() {
  return (
    <main id="main-content" className="body-container-wrapper">
      <div className="body-container body-container--blog-post">
        {/* subsribe newsletter */}
        <div
          id="hs_cos_wrapper_frosty_blog_post_subscribe_form"
          className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
          style={{}}
          data-hs-cos-general-type="widget"
          data-hs-cos-type="module"
        >
          <section className="frosty-blog-post-subscribe-form  ">
            <div className="content-wrapper">
              <div
                className="subscribe-form-wrap px-40 py-40 border-radius box-shadow mr-auto ml-auto"
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "space-around",
                  padding: "20px",
                  backgroundColor: "#FFF9F3",
                }}
              >
                <div className="heading-text">
                  <h3>
                    <span className="heading-type heading-color font-size-">
                      Subscribe to our newsletter today!
                    </span>
                  </h3>
                </div>
                <form
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "570px",
                  }}
                >
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Email Address"
                    style={{
                      height: "42px",
                      padding: "10px",
                      borderTopRightRadius: "5px",
                      borderBottomRightRadius: "5px",
                      border: "1px solid #F2F2F6",
                      marginRight: "12px",
                      width: "calc(100% - 100px)", // Adjust width to fit both inputs
                    }}
                  />
                  <button
                    type="submit"
                    style={{
                      height: "50px",
                      padding: "10px",
                      backgroundColor: "#e85900c7",
                      borderTopLeftRadius: "5px",
                      borderBottomLeftRadius: "5px",
                      border: "1px solid red",
                      color: "white",
                      cursor: "pointer",
                      width: "250px",
                      // Adjust width to fit both inputs
                    }}
                  >
                    Get Started
                  </button>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
}
