import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const API_URL = "https://script.googleusercontent.com/macros/echo?user_content_key=pWh5c0T6MGvRhFAQPy8e7wsQuPSaxUJIRb84ahmn3M_Qh85fRKt5Gnl8NTQAllEbst3iNxLEgBgc3ysqu8hjXRRGDwusM2ism5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnKfQFJrxQNXqz_hfmCuxp__vXh_dn2zgv728EPFVsdDO5lbsuzCeXjX-cSr8OSCFBmAi1TpL04ipVsA0BIGe_lEs5clFe3_t59z9Jw9Md8uu&lib=MAK88FHEYXBbJikQUAVbKZqxtnBi4VF6q"

export default function Footer() {

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  const getProduct = async () => {
    try {
      // Check if products and categories exist in local storage
      const storedProducts = localStorage.getItem('productss');
      const storedCategories = localStorage.getItem('categoriess');
      if (storedProducts && storedCategories) {
        setProducts(JSON.parse(storedProducts));
        setCategories(JSON.parse(storedCategories));
        return; // Exit the function if data is found in local storage
      }
  
      const response = await axios.get(API_URL);
      if (response.status === 200) {
        const fetchedProducts = response.data.data;
        localStorage.setItem('products', JSON.stringify(fetchedProducts));
        setProducts(fetchedProducts);
  
        // Extract unique categories and set them in state
        const uniqueCategories = [...new Set(fetchedProducts.map(item => item.category))];
        setCategories(uniqueCategories);
        localStorage.setItem('categories', JSON.stringify(uniqueCategories));
  
      }
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    getProduct();
  }, [products]);
  return (
    <div data-global-resource-path="#">
      <footer className="footer">
        <div className="container-fluid footer__container">
          <div className="row-fluid-wrapper">
            <div className="row-fluid">
              <div
                className="span12 widget-span widget-type-cell "
                style={{}}
                data-widget-type="cell"
                data-x={0}
                data-w={12}
              >
                <div className="row-fluid-wrapper row-depth-1 row-number-1 dnd-section footer-row-0-padding">
                  <div className="row-fluid ">
                    <div
                      className="span4 widget-span widget-type-cell dnd-column"
                      style={{}}
                      data-widget-type="cell"
                      data-x={0}
                      data-w={4}
                    >
                      <div className="row-fluid-wrapper row-depth-1 row-number-2 footer-column-1-row-0-margin dnd-row">
                        <div className="row-fluid ">
                          <div
                            className="span12 widget-span widget-type-custom_widget footer-module-2-flexbox-positioning dnd-module"
                            style={{}}
                            data-widget-type="custom_widget"
                            data-x={0}
                            data-w={12}
                          >
                            <div
                              id="hs_cos_wrapper_footer-module-2"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-logo"
                              style={{}}
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="module"
                            >
                              <span
                                id="hs_cos_wrapper_footer-module-2_hs_logo_widget"
                                className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_logo"
                                style={{}}
                                data-hs-cos-general-type="widget"
                                data-hs-cos-type="logo"
                              >
                                <Link
                                  to="#"
                                  id="hs-link-footer-module-2_hs_logo_widget"
                                  style={{ borderWidth: 0, border: 0 }}
                                >
                                  <img
                                    src="https://res.cloudinary.com/diyncva2v/image/upload/v1711338508/conkultsqnuonvuystk6.png"
                                    className="hs-image-widget "
                                    height={26}
                                    style={{
                                      height: "auto",
                                      width: 170,
                                      borderWidth: 0,
                                      border: 0,
                                    }}
                                    width={170}
                                    alt="WahFood"
                                    title="WahFood"
                                  />
                                </Link>
                              </span>
                            </div>
                          </div>
                          {/*end widget-span */}
                        </div>
                        {/*end row*/}
                      </div>
                      {/*end row-wrapper */}
                      <div className="row-fluid-wrapper row-depth-1 row-number-3 dnd-row footer-column-1-row-1-margin">
                        <div className="row-fluid ">
                          <div
                            className="span12 widget-span widget-type-custom_widget dnd-module"
                            style={{}}
                            data-widget-type="custom_widget"
                            data-x={0}
                            data-w={12}
                          >
                            <div
                              id="hs_cos_wrapper_footer-module-3"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text"
                              style={{}}
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="module"
                            >
                              <span
                                id="hs_cos_wrapper_footer-module-3_"
                                className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text"
                                style={{}}
                                data-hs-cos-general-type="widget"
                                data-hs-cos-type="rich_text"
                              >
                                <p>
                                  Welcome to Wah! Foods , flavoured hygienic Puchka & authentic Litti Chokha Specially crafted with Care to delight your taste buds.
                                </p>
                              </span>
                            </div>
                          </div>
                          {/*end widget-span */}
                        </div>
                        {/*end row*/}
                      </div>
                      {/*end row-wrapper */}
                    </div>
                    {/*end widget-span */}
                    <div
                      className="span8 widget-span widget-type-cell dnd-column"
                      style={{}}
                      data-widget-type="cell"
                      data-x={4}
                      data-w={8}
                    >
                      <div className="row-fluid-wrapper row-depth-1 row-number-4 footer-column-4-row-0-margin dnd-row">
                        <div className="row-fluid ">
                          <div
                            className="span4 widget-span widget-type-cell dnd-column"
                            style={{}}
                            data-widget-type="cell"
                            data-x={0}
                            data-w={4}
                          >
                            <div className="row-fluid-wrapper row-depth-1 row-number-5 dnd-row">
                              <div className="row-fluid ">
                                <div
                                  className="span12 widget-span widget-type-custom_widget dnd-module"
                                  style={{}}
                                  data-widget-type="custom_widget"
                                  data-x={0}
                                  data-w={12}
                                >
                                  <div
                                    id="hs_cos_wrapper_footer-module-6"
                                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header"
                                    style={{}}
                                    data-hs-cos-general-type="widget"
                                    data-hs-cos-type="module"
                                  >

                                  </div>
                                </div>
                                {/*end widget-span */}
                              </div>
                              {/*end row*/}
                            </div>
                            {/*end row-wrapper */}
                            <div className="row-fluid-wrapper row-depth-1 row-number-6 dnd-row footer-column-5-row-1-margin">
                              <div className="row-fluid ">
                                <div
                                  className="span12 widget-span widget-type-custom_widget dnd-module"
                                  style={{}}
                                  data-widget-type="custom_widget"
                                  data-x={0}
                                  data-w={12}
                                >

                                </div>
                                {/*end widget-span */}
                              </div>
                              {/*end row*/}
                            </div>
                            {/*end row-wrapper */}
                          </div>
                          {/*end widget-span */}
                          <div
                            className="span4 widget-span widget-type-cell dnd-column"
                            style={{}}
                            data-widget-type="cell"
                            data-x={4}
                            data-w={4}
                          >
                            <div className="row-fluid-wrapper row-depth-1 row-number-7 dnd-row">
                              <div className="row-fluid ">
                                <div
                                  className="span12 widget-span widget-type-custom_widget dnd-module"
                                  style={{}}
                                  data-widget-type="custom_widget"
                                  data-x={0}
                                  data-w={12}
                                >
                                  <div
                                    id="hs_cos_wrapper_footer-module-9"
                                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header"
                                    style={{}}
                                    data-hs-cos-general-type="widget"
                                    data-hs-cos-type="module"
                                  >
                                    <span
                                      id="hs_cos_wrapper_footer-module-9_"
                                      className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header"
                                      style={{}}
                                      data-hs-cos-general-type="widget"
                                      data-hs-cos-type="header"
                                    >
                                      <h4>Menu</h4>
                                    </span>
                                  </div>
                                </div>
                                {/*end widget-span */}
                              </div>
                              {/*end row*/}
                            </div>
                            {/*end row-wrapper */}
                            <div className="row-fluid-wrapper row-depth-1 row-number-8 footer-column-8-row-1-margin dnd-row">
                              <div className="row-fluid ">
                                <div
                                  className="span12 widget-span widget-type-custom_widget dnd-module"
                                  style={{}}
                                  data-widget-type="custom_widget"
                                  data-x={0}
                                  data-w={12}
                                >
                                  <div
                                    id="hs_cos_wrapper_footer-module-10"
                                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-simple_menu"
                                    style={{}}
                                    data-hs-cos-general-type="widget"
                                    data-hs-cos-type="module"
                                  >
                                    <span
                                      id="hs_cos_wrapper_footer-module-10_"
                                      className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_simple_menu"
                                      style={{}}
                                      data-hs-cos-general-type="widget"
                                      data-hs-cos-type="simple_menu"
                                    >
                                      <div
                                        id="hs_menu_wrapper_footer-module-10_"
                                        className="hs-menu-wrapper active-branch flyouts hs-menu-flow-vertical"
                                        role="navigation"
                                        data-sitemap-name
                                        data-menu-id
                                        aria-label="Navigation Menu"
                                      >
                                        <ul role="menu">
                                          {
                                            categories?.map((category, index) => (
                                              <li
                                                className="hs-menu-item hs-menu-depth-1"
                                                role="none"
                                                key={index}
                                              >
                                                <Link
                                                  to={`/menu?category=${encodeURIComponent(category.name)}`}
                                                  role="menuitem"
                                                  target="_self"
                                                >
                                                  {category}
                                                </Link>
                                              </li>
                                            ))
                                          }


                                        </ul>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                {/*end widget-span */}
                              </div>
                              {/*end row*/}
                            </div>
                            {/*end row-wrapper */}
                          </div>
                          {/*end widget-span */}
                          <div
                            className="span4 widget-span widget-type-cell dnd-column"
                            style={{}}
                            data-widget-type="cell"
                            data-x={8}
                            data-w={4}
                          >
                            <div className="row-fluid-wrapper row-depth-1 row-number-9 dnd-row">
                              <div className="row-fluid ">
                                <div
                                  className="span12 widget-span widget-type-custom_widget dnd-module"
                                  style={{}}
                                  data-widget-type="custom_widget"
                                  data-x={0}
                                  data-w={12}
                                >
                                  <div
                                    id="hs_cos_wrapper_footer-module-12"
                                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header"
                                    style={{}}
                                    data-hs-cos-general-type="widget"
                                    data-hs-cos-type="module"
                                  >
                                    <span
                                      id="hs_cos_wrapper_footer-module-12_"
                                      className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header"
                                      style={{}}
                                      data-hs-cos-general-type="widget"
                                      data-hs-cos-type="header"
                                    >
                                      <h4>Our Pages</h4>
                                    </span>
                                  </div>
                                </div>
                                {/*end widget-span */}
                              </div>
                              {/*end row*/}
                            </div>
                            {/*end row-wrapper */}
                            <div className="row-fluid-wrapper row-depth-1 row-number-10 footer-column-11-row-1-margin dnd-row">
                              <div className="row-fluid ">
                                <div
                                  className="span12 widget-span widget-type-custom_widget dnd-module"
                                  style={{}}
                                  data-widget-type="custom_widget"
                                  data-x={0}
                                  data-w={12}
                                >
                                  <div
                                    id="hs_cos_wrapper_footer-module-13"
                                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-simple_menu"
                                    style={{}}
                                    data-hs-cos-general-type="widget"
                                    data-hs-cos-type="module"
                                  >
                                    <span
                                      id="hs_cos_wrapper_footer-module-13_"
                                      className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_simple_menu"
                                      style={{}}
                                      data-hs-cos-general-type="widget"
                                      data-hs-cos-type="simple_menu"
                                    >
                                      <div
                                        id="hs_menu_wrapper_footer-module-7_"
                                        className="hs-menu-wrapper active-branch flyouts hs-menu-flow-vertical"
                                        role="navigation"
                                        data-sitemap-name
                                        data-menu-id
                                        aria-label="Navigation Menu"
                                      >
                                        <ul
                                          role="menu"
                                          className="active-branch"
                                        >
                                          <li
                                            className="hs-menu-item hs-menu-depth-1 active active-branch"
                                            role="none"
                                          >
                                            <Link
                                              to="/"
                                              role="menuitem"
                                              target="_self"
                                            >
                                              Home
                                            </Link>
                                          </li>
                                          <li
                                            className="hs-menu-item hs-menu-depth-1"
                                            role="none"
                                          >
                                            <Link
                                              to="/about"
                                              role="menuitem"
                                              target="_self"
                                            >
                                              About
                                            </Link>
                                          </li>
                                          <li
                                            className="hs-menu-item hs-menu-depth-1"
                                            role="none"
                                          >
                                            <Link
                                              to="/menu"
                                              role="menuitem"
                                              target="_self"
                                            >
                                              Menu
                                            </Link>
                                          </li>
                                          <li
                                            className="hs-menu-item hs-menu-depth-1"
                                            role="none"
                                          >
                                            <Link
                                              to="/contact"
                                              role="menuitem"
                                              target="_self"
                                            >
                                              Contact
                                            </Link>
                                          </li>
                                          {/* <li
                                            className="hs-menu-item hs-menu-depth-1"
                                            role="none"
                                          >
                                            <a
                                              href="#"
                                              role="menuitem"
                                              target="_self"
                                            >
                                              Blog
                                            </a>
                                          </li>
                                          <li
                                            className="hs-menu-item hs-menu-depth-1"
                                            role="none"
                                          >
                                            <a
                                              href="#"
                                              role="menuitem"
                                              target="_self"
                                            >
                                              Blog Post
                                            </a>
                                          </li> */}
                                          <li
                                            className="hs-menu-item hs-menu-depth-1"
                                            role="none"
                                          >
                                            <Link
                                              to="/faq"
                                              role="menuitem"
                                              target="_self"
                                            >
                                              FAQs
                                            </Link>
                                          </li>
                                        </ul>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                {/*end widget-span */}
                              </div>
                              {/*end row*/}
                            </div>
                            {/*end row-wrapper */}
                          </div>
                          {/*end widget-span */}
                        </div>
                        {/*end row*/}
                      </div>
                      {/*end row-wrapper */}
                    </div>
                    {/*end widget-span */}
                  </div>
                  {/*end row*/}
                </div>
                {/*end row-wrapper */}
                <div className="row-fluid-wrapper row-depth-1 row-number-11 dnd-section footer-row-1-padding footer-row-1-vertical-alignment">
                  <div className="row-fluid ">
                    <div
                      className="span12 widget-span widget-type-cell dnd-column footer-column-14-vertical-alignment"
                      style={{}}
                      data-widget-type="cell"
                      data-x={0}
                      data-w={12}
                    >
                      <div className="row-fluid-wrapper row-depth-1 row-number-12 dnd-row footer-column-14-row-0-margin">
                        <div className="row-fluid ">
                          <div
                            className="span12 widget-span widget-type-custom_widget dnd-module"
                            style={{}}
                            data-widget-type="custom_widget"
                            data-x={0}
                            data-w={12}
                          >
                            <div
                              id="hs_cos_wrapper_footer-module-15"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                              style={{}}
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="module"
                            >
                              <hr
                                style={{
                                  width: "100%",
                                  border: "0 none",
                                  borderBottomWidth: 1,
                                  borderBottomStyle: "solid",
                                  borderBottomColor: "rgba(0, 0, 0, 1.0)",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  marginTop: 0,
                                  marginBottom: 0,
                                }}
                              />
                            </div>
                          </div>
                          {/*end widget-span */}
                        </div>
                        {/*end row*/}
                      </div>
                      {/*end row-wrapper */}
                    </div>
                    {/*end widget-span */}
                  </div>
                  {/*end row*/}
                </div>
                {/*end row-wrapper */}
                <div className="row-fluid-wrapper row-depth-1 row-number-13 dnd-section footer-row-2-padding footer-row-2-vertical-alignment">
                  <div className="row-fluid ">
                    <div
                      className="span6 widget-span widget-type-cell dnd-column footer-column-16-vertical-alignment"
                      style={{}}
                      data-widget-type="cell"
                      data-x={0}
                      data-w={6}
                    >
                      <div className="row-fluid-wrapper row-depth-1 row-number-14 dnd-row">
                        <div className="row-fluid ">
                          <div
                            className="span12 widget-span widget-type-custom_widget dnd-module"
                            style={{}}
                            data-widget-type="custom_widget"
                            data-x={0}
                            data-w={12}
                          >
                            <div
                              id="hs_cos_wrapper_footer-module-17"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module footer-simple-menu widget-type-simple_menu"
                              style={{}}
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="module"
                            >
                              <span
                                id="hs_cos_wrapper_footer-module-17_"
                                className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_simple_menu"
                                style={{}}
                                data-hs-cos-general-type="widget"
                                data-hs-cos-type="simple_menu"
                              >
                                <div
                                  id="hs_menu_wrapper_footer-module-17_"
                                  className="hs-menu-wrapper active-branch flyouts hs-menu-flow-horizontal"
                                  role="navigation"
                                  data-sitemap-name
                                  data-menu-id
                                  aria-label="Navigation Menu"
                                >
                                  <ul role="menu" className="menu-list-footer-terms">
                                    <li
                                      className="hs-menu-item hs-menu-depth-1"
                                      role="none"
                                    >
                                      <Link
                                        to="#"
                                        role="menuitem"
                                        target="_self"
                                      >
                                        Terms and Conditions
                                      </Link>
                                    </li>
                                    <li
                                      className="hs-menu-item hs-menu-depth-1"
                                      role="none"
                                    >
                                      <Link
                                        to="#"
                                        role="menuitem"
                                        target="_self"
                                      >
                                        Privacy Policy
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </span>
                            </div>
                          </div>
                          {/*end widget-span */}
                        </div>
                        {/*end row*/}
                      </div>
                      {/*end row-wrapper */}
                    </div>
                    {/*end widget-span */}
                    <div
                      className="span6 widget-span widget-type-cell footer-column-18-vertical-alignment dnd-column"
                      style={{}}
                      data-widget-type="cell"
                      data-x={6}
                      data-w={6}
                    >
                      <div className="row-fluid-wrapper row-depth-1 row-number-15 footer-column-18-row-0-vertical-alignment footer-column-18-row-0-margin dnd-row">
                        <div className="row-fluid ">
                          <div
                            className="span12 widget-span widget-type-custom_widget footer-module-19-vertical-alignment dnd-module"
                            style={{}}
                            data-widget-type="custom_widget"
                            data-x={0}
                            data-w={12}
                          >
                            <div
                              id="hs_cos_wrapper_footer-module-19"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module footer-social-follow"
                              style={{display: 'flex' , justifyContent: 'flex-end'}}
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="module"
                            >
                              <style
                                dangerouslySetInnerHTML={{
                                  __html:
                                    "\n                                #hs_cos_wrapper_footer-module-19 .social-links {}\n\n                                #hs_cos_wrapper_footer-module-19 .social-links__link {}\n\n                                #hs_cos_wrapper_footer-module-19 .social-links__icon {\n                                  background-color: rgba(255, 255, 255, 1.0);\n                                }\n\n                                #hs_cos_wrapper_footer-module-19 .social-links__icon svg {\n                                  fill: #263238;\n                                  height: 16px;\n                                  width: 16px;\n                                }\n\n                                #hs_cos_wrapper_footer-module-19 .social-links__icon:hover,\n                                #hs_cos_wrapper_footer-module-19 .social-links__icon:focus {\n                                  background-color: rgba(235, 235, 235, 1.0);\n                                }\n\n                                #hs_cos_wrapper_footer-module-19 .social-links__icon:active {\n                                  background-color: rgba(225, 225, 225, 1.0);\n                                }\n                              ",
                                }}
                              />
                              <div className="social-links" style={{display: 'flex', flexDirection: 'column'}}>
                                <p>Wah! Puchka Social</p>
                               <div style={{display: 'flex'}}>
                               <Link
                                  className="social-links__link"
                                  to="https://www.instagram.com/wahpuchka?igsh=dmVibjlqOHR6YmYw"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span
                                    id="hs_cos_wrapper_footer-module-19_"
                                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon social-links__icon"
                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                    data-hs-cos-general-type="widget"
                                    data-hs-cos-type="icon"
                                  >
                                    <i class="fa fa-instagram" style={{fontSize: '24px'}}></i>

                                  </span>
                                </Link>
                                <Link
                                  className="social-links__link"
                                  to="https://www.facebook.com/wahpuchka?mibextid=LQQJ4d"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span
                                    id="hs_cos_wrapper_footer-module-19_"
                                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon social-links__icon"
                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                    data-hs-cos-general-type="widget"
                                    data-hs-cos-type="icon"
                                  >
                                    <i class="fa fa-facebook-f" style={{fontSize: '24px'}}></i>

                                  </span>
                                </Link>
                                <Link
                                  className="social-links__link"
                                  to="https://www.linkedin.com/company/wah-phuchka/"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span
                                    id="hs_cos_wrapper_footer-module-19_"
                                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon social-links__icon"
                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                    data-hs-cos-general-type="widget"
                                    data-hs-cos-type="icon"
                                  >
                                    <i class="fa fa-linkedin-square" style={{fontSize: '24px'}}></i>

                                  </span>
                                </Link>
                               </div>
                              </div>
                              <div className="social-links" style={{display: 'flex', flexDirection: 'column', marginLeft: '20px'}}>
                                <p>Wah! Litti Social</p>
                               <div style={{display: 'flex'}}>
                               <Link
                                  className="social-links__link"
                                  to="https://www.instagram.com/wahlitti?igsh=MTJ1Y2xxbnRpZzdxZQ=="
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span
                                    id="hs_cos_wrapper_footer-module-19_"
                                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon social-links__icon"
                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                    data-hs-cos-general-type="widget"
                                    data-hs-cos-type="icon"
                                  >
                                    <i class="fa fa-instagram" style={{fontSize: '24px'}}></i>

                                  </span>
                                </Link>
                                <Link
                                  className="social-links__link"
                                  to="https://www.facebook.com/Wahlitti?mibextid=LQQJ4d"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span
                                    id="hs_cos_wrapper_footer-module-19_"
                                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon social-links__icon"
                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                    data-hs-cos-general-type="widget"
                                    data-hs-cos-type="icon"
                                  >
                                    <i class="fa fa-facebook-f" style={{fontSize: '24px'}}></i>

                                  </span>
                                </Link>
                                <Link
                                  className="social-links__link"
                                  to="https://www.linkedin.com/company/wah-litti/"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span
                                    id="hs_cos_wrapper_footer-module-19_"
                                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon social-links__icon"
                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                    data-hs-cos-general-type="widget"
                                    data-hs-cos-type="icon"
                                  >
                                    <i class="fa fa-linkedin-square" style={{fontSize: '24px'}}></i>

                                  </span>
                                </Link>
                               </div>
                              </div>
                            </div>
                          </div>
                          {/*end widget-span */}
                        </div>
                        {/*end row*/}
                      </div>
                      {/*end row-wrapper */}
                    </div>
                    {/*end widget-span */}
                  </div>
                  {/*end row*/}
                </div>
                {/*end row-wrapper */}
              </div>
              {/*end widget-span */}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
