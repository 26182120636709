import React, { useEffect, useState } from "react";
import Header from "../Layout/Header";
import Hero from "../components/Home/Hero";
import FavApp from "../components/Home/FavApp";
import OurMenu from "../components/Home/OurMenu";
// import Articles from "../components/Home/Articles";
import Footer from "../Layout/Footer";
import AboutUs from "../components/Home/AboutUs";
import axios from "axios";
// import BounceLoader from "react-spinners/BounceLoader";

const API_URL = "https://script.googleusercontent.com/macros/echo?user_content_key=pWh5c0T6MGvRhFAQPy8e7wsQuPSaxUJIRb84ahmn3M_Qh85fRKt5Gnl8NTQAllEbst3iNxLEgBgc3ysqu8hjXRRGDwusM2ism5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnKfQFJrxQNXqz_hfmCuxp__vXh_dn2zgv728EPFVsdDO5lbsuzCeXjX-cSr8OSCFBmAi1TpL04ipVsA0BIGe_lEs5clFe3_t59z9Jw9Md8uu&lib=MAK88FHEYXBbJikQUAVbKZqxtnBi4VF6q"

export default function Home() {

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  const getProduct = async () => {
    try {
      // Check if products and categories exist in local storage
      const storedProducts = localStorage.getItem('productss');
      const storedCategories = localStorage.getItem('categoriess');
      if (storedProducts && storedCategories) {
        setProducts(JSON.parse(storedProducts));
        setCategories(JSON.parse(storedCategories));
        return; // Exit the function if data is found in local storage
      }
  
      const response = await axios.get(API_URL);
      if (response.status === 200) {
        const fetchedProducts = response.data.data;
        localStorage.setItem('products', JSON.stringify(fetchedProducts));
        setProducts(fetchedProducts);
  
        // Extract unique categories and set them in state
        const uniqueCategories = [...new Set(fetchedProducts.map(item => item.category))];
        setCategories(uniqueCategories);
        localStorage.setItem('categories', JSON.stringify(uniqueCategories));
  
      }
    } catch (error) {
      console.log(error);
    }
  }
 

  useEffect(() => {
    getProduct();
  }, [products]);
  
  return (
    <div className="body-wrapper hs-content-id-80722938217 hs-site-page page hs-content-path-frosty hs-content-name-frosty-home  ">
      <Header />
      <main id="main-content" className="body-container-wrapper">
        <div className="container-fluid body-container body-container--home container-fluid-custom">
          <div className="row-fluid-wrapper">
            <div className="row-fluid">
              <div
                className="span12 widget-span widget-type-cell "
                style={{}}
                data-widget-type="cell"
                data-x={0}
                data-w={12}
              >
                <Hero />
                <FavApp />
                <OurMenu categories={categories}/>
                <AboutUs />
                {/* <Articles /> */}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer categories={categories} />
    </div>
  );
}
