import React from 'react'

export default function RelatedPosts() {
  return (
    <main id="main-content" className="body-container-wrapper">
        <div className="body-container body-container--blog-post">
        <div
          id="hs_cos_wrapper_frosty_blog_post_related_posts"
          className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
          style={{}}
          data-hs-cos-general-type="widget"
          data-hs-cos-type="module"
        >
          <section className="frosty-blog-post-related-posts text-center">
            <div className="content-wrapper">
              <div className="tagline-wrap mb-15">
                <div className="tagline secondary-color">Related Posts</div>
              </div>
              <div className="heading-text">
                <h2>
                  <span className="heading-type heading-color font-size-30">
                    You may also like this
                  </span>
                </h2>
              </div>
              <div className="mb-30">
                <p>
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique.
                  </span>
                </p>
              </div>
              <div className="flex">
                <div className="col">
                  <div
                    className="blog-card border-radius text-left"
                    aria-label="Blog post summary: How to prepare the perfect french fries?"
                  >
                    <div className="blog-card-featured-image text-center">
                      <a
                        href="how-to-prepare-the-perfect-french-fries.html"
                        aria-label="Read full post: How to prepare the perfect french fries?"
                      >
                        <img
                          src="https://info.juicetactics.com/hubfs/pexels-kalz-2498440.jpg"
                          className="featured-image"
                          alt
                          loading="lazy"
                        />
                      </a>
                    </div>
                    <div className="blog-card-body pt-20 pb-40">
                      <div className="blog-card-topic mb-10">
                        <a
                          className="btn btn-primary btn-sm py-1 px-2 rounded-0 mt-3 me-3"
                          href="tag/articles.html"
                        >
                          Articles
                        </a>
                      </div>
                      <div className="blog-post-name mb-10">
                        <h3 className="font-size-24 mb-0">
                          <a href="how-to-prepare-the-perfect-french-fries.html">
                            How to prepare the perfect french fries?
                          </a>
                        </h3>
                      </div>
                      <div className="blog-card-date font-size-14 text-uppercase mb-20">
                        October 20, 2021
                      </div>
                      <div className="blog-card-text">
                        What does the perfect hamburger taste like? Lorem ipsum
                        dolor sit amet, consectetur adipiscing elit lobortis
                        arcu enim...
                      </div>
                      <div className="blog-card-readmore mt-30">
                        <a
                          className="button"
                          href="how-to-prepare-the-perfect-french-fries.html"
                        >
                          Read more
                          <span
                            id="hs_cos_wrapper_frosty_blog_post_related_posts_"
                            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon"
                            style={{}}
                            data-hs-cos-general-type="widget"
                            data-hs-cos-type="icon"
                          >
                            <svg
                              version={1.0}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 384 512"
                              width={16}
                              height={16}
                              aria-hidden="true"
                            >
                              <g id="Alternate File1_layer">
                                <path d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z" />
                              </g>
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div
                    className="blog-card border-radius text-left"
                    aria-label="Blog post summary: 4 easy recipes to prepare a delicious pancakes"
                  >
                    <div className="blog-card-featured-image text-center">
                      <a
                        href="4-easy-recipes-to-prepare-a-delicious-homemade-pancakes.html"
                        aria-label="Read full post: 4 easy recipes to prepare a delicious pancakes"
                      >
                        <img
                          src="https://info.juicetactics.com/hubfs/pexels-kalz-2498440.jpg"
                          className="featured-image"
                          alt
                          loading="lazy"
                        />
                      </a>
                    </div>
                    <div className="blog-card-body pt-20 pb-40">
                      <div className="blog-card-topic mb-10">
                        <a
                          className="btn btn-primary btn-sm py-1 px-2 rounded-0 mt-3 me-3"
                          href="tag/recipes.html"
                        >
                          Recipes
                        </a>
                      </div>
                      <div className="blog-post-name mb-10">
                        <h3 className="font-size-24 mb-0">
                          <a href="4-easy-recipes-to-prepare-a-delicious-homemade-pancakes.html">
                            4 easy recipes to prepare a delicious pancakes
                          </a>
                        </h3>
                      </div>
                      <div className="blog-card-date font-size-14 text-uppercase mb-20">
                        September 1, 2022
                      </div>
                      <div className="blog-card-text">
                        What does the perfect hamburger taste like? Lorem ipsum
                        dolor sit amet, consectetur adipiscing elit lobortis
                        arcu enim...
                      </div>
                      <div className="blog-card-readmore mt-30">
                        <a
                          className="button"
                          href="4-easy-recipes-to-prepare-a-delicious-homemade-pancakes.html"
                        >
                          Read more
                          <span
                            id="hs_cos_wrapper_frosty_blog_post_related_posts_"
                            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon"
                            style={{}}
                            data-hs-cos-general-type="widget"
                            data-hs-cos-type="icon"
                          >
                            <svg
                              version={1.0}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 384 512"
                              width={16}
                              height={16}
                              aria-hidden="true"
                            >
                              <g id="Alternate File1_layer">
                                <path d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z" />
                              </g>
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div
                    className="blog-card border-radius text-left"
                    aria-label="Blog post summary: How to prepare buttermilk fried chicken tenders"
                  >
                    <div className="blog-card-featured-image text-center">
                      <a
                        href="how-to-prepare-buttermilk-fried-chicken-tenders.html"
                        aria-label=" Featured image: Buttermilk Fried Chicken Tenders - Read full post: How to prepare buttermilk fried chicken tenders"
                      >
                        <img
                          src="https://info.juicetactics.com/hubfs/pexels-kalz-2498440.jpg"
                          className="featured-image"
                          alt="Buttermilk Fried Chicken Tenders"
                          loading="lazy"
                        />
                      </a>
                    </div>
                    <div className="blog-card-body pt-20 pb-40">
                      <div className="blog-card-topic mb-10">
                        <a
                          className="btn btn-primary btn-sm py-1 px-2 rounded-0 mt-3 me-3"
                          href="tag/recipes.html"
                        >
                          Recipes
                        </a>
                      </div>
                      <div className="blog-post-name mb-10">
                        <h3 className="font-size-24 mb-0">
                          <a href="how-to-prepare-buttermilk-fried-chicken-tenders.html">
                            How to prepare buttermilk fried chicken tenders
                          </a>
                        </h3>
                      </div>
                      <div className="blog-card-date font-size-14 text-uppercase mb-20">
                        September 1, 2022
                      </div>
                      <div className="blog-card-text">
                        What does the perfect hamburger taste like? Lorem ipsum
                        dolor sit amet, consectetur adipiscing elit lobortis
                        arcu enim...
                      </div>
                      <div className="blog-card-readmore mt-30">
                        <a
                          className="button"
                          href="how-to-prepare-buttermilk-fried-chicken-tenders.html"
                        >
                          Read more
                          <span
                            id="hs_cos_wrapper_frosty_blog_post_related_posts_"
                            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon"
                            style={{}}
                            data-hs-cos-general-type="widget"
                            data-hs-cos-type="icon"
                          >
                            <svg
                              version={1.0}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 384 512"
                              width={16}
                              height={16}
                              aria-hidden="true"
                            >
                              <g id="Alternate File1_layer">
                                <path d="M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z" />
                              </g>
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        </div>
    </main>
  )
}
