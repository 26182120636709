import React from 'react';
import StoryItem from './StoryItme'

export default function Story() {
  return (
    <div className="row-fluid-wrapper row-depth-1 row-number-7 dnd-section dnd_area-row-3-background-color">
      <div className="row-fluid ">
        <div className="span12 widget-span widget-type-cell dnd-column" style={{}} data-widget-type="cell" data-x={0} data-w={12}>
          <div className="row-fluid-wrapper row-depth-1 row-number-8 dnd-row">
            <div className="row-fluid ">
              <div className="span12 widget-span widget-type-custom_widget dnd-module" style={{}} data-widget-type="custom_widget" data-x={0} data-w={12}>
                <div id="hs_cos_wrapper_widget_1662017146177" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                {/* <section className="frosty-hero-1 ">
                    <div className="flex flex--left-image">
                      <div className="col">
                        <img className="img-block border-radius" src="https://info.juicetactics.com/hubfs/pexels-valeria-boltneva-1639557.jpg" alt="pexels-valeria-boltneva-1639557" loading="lazy" style={{ maxWidth: '100%', height: 'auto' }} />
                      </div>
                      <div className="col">
                        <div className="heading-text">
                          <h2>
                            <span className="heading-type heading-color font-size-0">
                              The story of our team
                            </span>
                          </h2>
                        </div>
                        <div className="mb-40">
                          <p>Lorem ipsum dolor sit amet consectetur adipiscing elit ugue quam diam vitae velit
                            bibendum elementum eget non vivamus volutpat odio cras vestibulum purus
                            aliquam.<br /><br />Lorem ipsum dolor sit amet consectetur adipiscing elit enim
                            bibendum sed et aliquet aliquet risus tempor semper odio egestas id pulvinar
                            consectetur elit tortor non hac pellentesque lacus donec accumsan quisque
                            ultricies adipiscing mauris tortor cras est eu accumsan mauris.</p>
                        </div>
                        <div className="button-wrap">
                          <div className="button-1 button-col">
                            <a className="button" href="#">
                              Contact Us
                            </a>
                          </div>
                          <div className="button-2 button-col">
                            <a className="button-outline" href="#">
                              Join our team
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section> */}
                  <StoryItem />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
