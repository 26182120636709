import React from 'react'

export default function FreeRecipe() {
  return (
    <main id="main-content" className="body-container-wrapper">
        <div className="body-container body-container--blog-post">
             {/* free recipe */}
        <div
          id="hs_cos_wrapper_frosty_blog_post_cta"
          className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
          style={{}}
          data-hs-cos-general-type="widget"
          data-hs-cos-type="module"
        >
          <section className="frosty-blog-post-cta text-center">
            <div className="content-wrapper">
              <div className="border-radius cta-wrap px-40 py-60 box-shadow mr-auto ml-auto">
                <div className="tagline-wrap mb-15">
                  <div className="tagline secondary-color">Free Recipe</div>
                </div>
                <div className="heading-text">
                  <h3>
                    <span className="heading-type heading-color font-size-">
                      The secret trick to preparing a perfect hamburger
                    </span>
                  </h3>
                </div>
                <div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique.
                  </p>
                </div>
                <div className="mt-30">
                  <a className="button" href="../contact.html">
                    Download Recipe
                    <span
                      id="hs_cos_wrapper_frosty_blog_post_cta_"
                      className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon"
                      style={{}}
                      data-hs-cos-general-type="widget"
                      data-hs-cos-type="icon"
                    >
                      <svg
                        version={1.0}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        width={16}
                        height={16}
                        aria-hidden="true"
                      >
                        <g id="Info Circle1_layer">
                          <path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z" />
                        </g>
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
        </div>
    </main>
  )
}
