import React from "react";
import Header from "../Layout/Header";
import OutBlog from "../components/BlogListing/OurBlog";
import Contact from "../components/BlogListing/Contact";
import Subscribe from "../components/BlogListing/Subscribe";
import Footer from "../Layout/Footer";
import BlogCards from "../components/BlogListing/BlogCards";

export default function BlogListing() {
  return (
    <div className="body-wrapper   hs-content-id-83644642233 hs-blog-listing hs-content-path-frosty-blog hs-content-name-frosty-blog-listing-page hs-blog-name-frosty-blog hs-blog-id-83644642232">
      <Header />
      <main id="main-content" className="body-container-wrapper">
        <div className="container-fluid body-container body-container--blog-index">
          <div className="row-fluid-wrapper">
            <div className="row-fluid">
              <div
                className="span12 widget-span widget-type-cell "
                style={{}}
                data-widget-type="cell"
                data-x={0}
                data-w={12}
              >
                <OutBlog />
                <BlogCards />
                <Contact />
                <Subscribe />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
