import React from "react";

export default function OutBlog() {
  return (
    <div className="row-fluid-wrapper row-depth-1 row-number-1 dnd_area-row-0-background-color dnd-section dnd_area-row-0-padding">
      <div className="row-fluid ">
        <div
          className="span12 widget-span widget-type-custom_widget dnd-module"
          style={{}}
          data-widget-type="custom_widget"
          data-x={0}
          data-w={12}
        >
          <div
            id="hs_cos_wrapper_dnd_area-module-1"
            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
            style={{}}
            data-hs-cos-general-type="widget"
            data-hs-cos-type="module"
          >
            <section className="frosty-blog-listing-hero">
              <div className="text-center">
                <div className="tagline-wrap mb-15">
                  <div className="tagline secondary-color">Our Blog</div>
                </div>
                <div className="heading-text">
                  <h1>
                    <span className="heading-type heading-color font-size-0">
                      Frosty Blog
                    </span>
                  </h1>
                </div>
                <div className="mb-30">
                  <p>
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur occaecat
                    cupidatat non
                  </p>
                </div>
                <div className="card-flex">
                  <div className="card-col card-content-wrap text-left">
                    <div className="card-title">
                      <h2>
                        <span className="card-title-color font-size-0">
                          The secret trick to prepare a perfect burger
                        </span>
                      </h2>
                    </div>
                    <div className="mb-30">
                      <p>
                        <span>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Suspendisse varius enim in eros elementum
                          tristique. Duis cursus, mi quis viverra ornare, eros
                          dolor interdum nulla.
                        </span>
                      </p>
                    </div>
                    <div>
                      <a className="button" href="blog.html">
                        Read Recipe
                        <span
                          id="hs_cos_wrapper_dnd_area-module-1_"
                          className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon"
                          style={{}}
                          data-hs-cos-general-type="widget"
                          data-hs-cos-type="icon"
                        >
                          <svg
                            version={1.0}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            width={16}
                            height={16}
                            aria-hidden="true"
                          >
                            <g id="Alternate Long Arrow Right1_layer">
                              <path d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z" />
                            </g>
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="card-col card-image-wrap">
                    <img
                      src="../../info.juicetactics.com/hs-fs/hubfs/blog-post-01831f.jpg?width=1600&height=897&name=blog-post-01.jpeg"
                      alt="blog-post-01"
                      loading
                      width={1600}
                      height={897}
                      srcSet="https://info.juicetactics.com/hs-fs/hubfs/blog-post-01.jpeg?width=800&height=449&name=blog-post-01.jpeg 800w, https://info.juicetactics.com/hs-fs/hubfs/blog-post-01.jpeg?width=1600&height=897&name=blog-post-01.jpeg 1600w, https://info.juicetactics.com/hs-fs/hubfs/blog-post-01.jpeg?width=2400&height=1346&name=blog-post-01.jpeg 2400w, https://info.juicetactics.com/hs-fs/hubfs/blog-post-01.jpeg?width=3200&height=1794&name=blog-post-01.jpeg 3200w, https://info.juicetactics.com/hs-fs/hubfs/blog-post-01.jpeg?width=4000&height=2243&name=blog-post-01.jpeg 4000w, https://info.juicetactics.com/hs-fs/hubfs/blog-post-01.jpeg?width=4800&height=2691&name=blog-post-01.jpeg 4800w"
                      sizes="(max-width: 1600px) 100vw, 1600px"
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/*end widget-span */}
      </div>
      {/*end row*/}
    </div>
  );
}
