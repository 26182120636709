import React from "react";
import { Link } from "react-router-dom";

export default function Hero() {
  return (
    <div className="row-fluid-wrapper row-depth-1 row-number-1 dnd-section"
    style={{
      backgroundImage: "url(https://res.cloudinary.com/diyncva2v/image/upload/v1711214204/ptvdypwbigpapjrvqoai.png)",
    }}
    >
      <div className="row-fluid " >
        <div
          className="span12 widget-span widget-type-cell dnd-column"
          data-widget-type="cell"
          data-x={0}
          data-w={12}

        >
          <div className="row-fluid-wrapper row-depth-1 row-number-2 dnd-row">
            <div className="row-fluid ">
              <div
                className="span12 widget-span widget-type-custom_widget dnd-module"
                style={{}}
                data-widget-type="custom_widget"
                data-x={0}
                data-w={12}
              >
                <div
                  id="hs_cos_wrapper_widget_1661927869387"
                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                  data-hs-cos-general-type="widget"
                  data-hs-cos-type="module"
                 
                >
                  <section className="frosty-hero-1 " >
                    <div className="flex flex--right-image">
                      <div className="col">
                        <img
                          className="img-block border-radius"
                          src="https://res.cloudinary.com/diyncva2v/image/upload/v1710618937/gfnk9me1zqvlqgsfhgvg.jpg"
                          alt="WahFood"
                          loading="lazy"
                          style={{
                            maxWidth: "100%",
                            height: "auto",
                          }}
                        />
                      </div>
                      <div className="col">
                        {/* <div className="tagline-wrap mb-15">
                          <div className="tagline secondary-color">
                            Free theme for Food &amp; Beverages
                          </div>
                        </div> */}
                        <div className="heading-text">
                          <h1>
                            <span className="heading-type heading-color font-size-0">
                              Taste Wahi Hygiene <br /> Sahi & Fusion Wah!<br /> So-Desi</span>
                          </h1>
                        </div>
                        <div className="mb-40">
                          <p>
                            Serving emotions with kolkata’s most popular handmade Puchka & Bihar’s 
                            authentic Litti Chokha in an absolute unique, healthier & hygienic way.
                          </p>
                        </div>
                        <div className="button-wrap">
                          <div className="button-1 button-col">
                            <a className="button" href="https://maps.app.goo.gl/Pf93HB2zHQdAeBXj6" target="_blank">
                            Share a Review                            
                            </a>
                          </div>
                          <div className="button-2 button-col">
                            <Link
                              className="button-outline"
                              to="/menu"
                            >
                              Order Online
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}
