import React from 'react';

function Error404Page() {
  const styles = {
    '*': {
      padding: 0,
      margin: 0,
      outline: 0,
      color: '#444',
      boxSizing: 'border-box',
      fontFamily: '"IBM Plex Sans", sans-serif'
    },
    body: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      overflow: 'hidden'
    },
    h1: {
      fontSize: '50px',
      lineHeight: '60px'
    },
    span: {
      display: 'block',
      fontSize: '18px',
      lineHeight: '30px'
    },
    container: {
      width: '80%',
      maxWidth: '1600px',
      margin: 'auto'
    },
    gridRow: {
      display: 'grid',
      justifyContent: 'center',
      placeItems: 'center',
      gridGap: '50px'
    },
    columnLeft: {
      textAlign: 'center'
    },
    goHome: {
      padding: '5px 20px',
      background: '#ffa000',
      border: 'transparent',
      borderRadius: '2px',
      boxShadow: '0 0 2px rgb(0 0 0 / 30%)',
      cursor: 'pointer',
      margin: '20px 0',
      color: '#fff'
    },
    goHomeIcon: {
      color: '#fff'
    },
    img: {
      display: 'block',
      width: '100%'
    }
  };

  return (
    <div style={styles.body}>
      <div style={styles.container}>
        <div style={styles.gridRow}>
          <div style={styles.columnLeft}>
            <img src="https://res.cloudinary.com/diyncva2v/image/upload/v1698957288/image-left_ha4zpa.webp" alt="image-left" style={styles.img} />
            <h1 style={styles.h1}>
              We can't find the page you are looking for.
            </h1>
            <span style={styles.span}>This page has been relocated or removed.</span>
            <a href="/">
              <button style={styles.goHome}><i className="fa fa-home" style={styles.goHomeIcon}></i> Go Home</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Error404Page;
