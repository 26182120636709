import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Home from "./pages/Home";
import Menu from "./pages/Menu";
import BlogListing from "./pages/BlogListing";
import BlogPosts from "./pages/BlogPosts";
import Contact from "./pages/Contact";
import FAQ from "./pages/FAQ";
import NotFound from './pages/not_found';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/blog-listing" element={<BlogListing />} />
        <Route path="/blog-post" element={<BlogPosts />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
