import React from "react";

export default function Form() {
  return (
    <main
      id="main-content"
      className="body-container-wrapper"
      style={{ marginBottom: "100px" }}
    >
      <div className="body-container body-container--blog-post">
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <label style={{ marginBottom: "10px", width: "40%" }}>
            First Name:
            <input
              type="text"
              name="firstName"
              style={{ marginBottom: "10px", padding: "10px" }}
            />
          </label>
          <label style={{ marginBottom: "10px", width: "40%" }}>
            Last Name:
            <input
              type="text"
              name="lastName"
              style={{ marginBottom: "10px", padding: "10px" }}
            />
          </label>
          <label style={{ marginBottom: "10px", width: "40%" }}>
            Email:
            <input
              type="email"
              name="email"
              style={{ marginBottom: "10px", padding: "10px" }}
            />
          </label>
          <label style={{ marginBottom: "10px", width: "40%" }}>
            Website:
            <input
              type="text"
              name="website"
              style={{ marginBottom: "10px", padding: "10px" }}
            />
          </label>
          <label style={{ marginBottom: "10px", width: "40%" }}>
            Comment:
            <textarea
              name="comment"
              style={{ marginBottom: "10px", padding: "10px" }}
            />
          </label>
          <button
            type="submit"
            style={{
              padding: "20px",
              width: "20%",
              backgroundColor: "#e85900c7",
              color: "white",
              border: "none",
              cursor: "pointer",
            }}
          >
            Submit Comment
          </button>
        </form>
      </div>
    </main>
  );
}
