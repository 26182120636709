import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export default function Header() {

  const [open, setOpen] = useState(false);

  return (
    <div data-global-resource-path="frosty/templates/partials/header.html">
        <header className="header">
          {/* <Link to="#main-content" className="header__skip">
            Skip to content
             active-item
          </Link> */}
          <div className="container-fluid content-wrapper">
            <div className="row-fluid-wrapper">
              <div className="row-fluid">
                <div
                  className="span12 widget-span widget-type-cell "
                  style={{}}
                  data-widget-type="cell"
                  data-x={0}
                  data-w={12}
                ></div>
              </div>
            </div>
          </div>
          <div className="header__container">
            <div className="header__column">
              <div className="header__row-1 ">
                <div className="header__row-1-container"></div>
              </div>
              <div className="header__row-2" id="header-row-2">
                <div className="header__logo header__logo--main">
                  <div
                    id="hs_cos_wrapper_site_logo"
                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-logo"
                    style={{}}
                    data-hs-cos-general-type="widget"
                    data-hs-cos-type="module"
                  >
                    <span
                      id="hs_cos_wrapper_site_logo_hs_logo_widget"
                      className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_logo"
                      style={{}}
                      data-hs-cos-general-type="widget"
                      data-hs-cos-type="logo"
                    >
                      <Link
                        to="/"
                        id="hs-link-site_logo_hs_logo_widget"
                        style={{ borderWidth: 0, border: 0 }}
                      >
                        <img
                          src="https://res.cloudinary.com/diyncva2v/image/upload/v1709463687/x50jmekfsxoebajh9tiq.png"
                          className="hs-image-widget "
                          height={26}
                          style={{
                            height: "auto",
                            width: 120,
                            borderWidth: 0,
                            border: 0,
                          }}
                          width={170}
                          
                        />
                      </Link>
                    </span>
                  </div>
                </div>
                <div
                  onClick={()=>{setOpen(!open)}}
                  className={`header--toggle header__navigation--toggle ${open ? 'hide' : ''} `}
                  id="header-navigation-toggle"
                />
                <div
                  onClick={()=>{setOpen(!open)}}
                  className={`header__close--toggle ${open ? 'show' : ''} `}
                  id="header-close-toggle"
                />
                <div
                  className={`header__navigation header--element ${open ? 'open' : ''} `}
                  id="header-element"
                >
                  <div
                    id="hs_cos_wrapper_navigation-primary"
                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                    style={{}}
                    data-hs-cos-general-type="widget"
                    data-hs-cos-type="module"
                  >
                    <nav aria-label="Main menu" className="navigation-primary">
                      <ul className="submenu level-1">
                        <li className="no-submenu menu-item hs-skip-lang-url-rewrite">
                          <Link
                            to="/"
                            className="menu-link "
                            aria-current="page"
                          >
                            Home
                          </Link>
                        </li>
                        <li className="no-submenu menu-item hs-skip-lang-url-rewrite">
                          <Link to="/about" className="menu-link">
                            About
                          </Link>
                        </li>
                        <li className="no-submenu menu-item hs-skip-lang-url-rewrite">
                          <Link to="/menu" className="menu-link">
                            Menu
                          </Link>
                        </li>
                        {/* <li className="has-submenu menu-item hs-skip-lang-url-rewrite">
                          <Link to="javascript:;" className="menu-link">
                            Blog
                          </Link>
                          <input
                            type="checkbox"
                            id="Blog"
                            className="submenu-toggle"
                          />
                          <label className="menu-arrow" htmlFor="Blog">
                            <span className="menu-arrow-bg" />
                          </label>
                          <ul className="submenu level-2">
                            <li className="no-submenu menu-item hs-skip-lang-url-rewrite">
                              <Link to="/blog-listing" className="menu-link">
                                Blog Listing
                              </Link>
                            </li>
                            <li className="no-submenu menu-item hs-skip-lang-url-rewrite">
                              <Link
                                to="/blog-post"
                                className="menu-link"
                              >
                                Blog Post
                              </Link>
                            </li>
                          </ul>
                        </li> */}
                        <li className="no-submenu menu-item hs-skip-lang-url-rewrite">
                          <Link to="/contact" className="menu-link">
                            Contact
                          </Link>
                        </li>
                        <li className="no-submenu menu-item hs-skip-lang-url-rewrite">
                          <Link to="/faq" className="menu-link">
                            FAQs
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div className="header__navigation-buttons">
                    <div
                      id="hs_cos_wrapper_menu_button"
                      className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                      style={{}}
                      data-hs-cos-general-type="widget"
                      data-hs-cos-type="module"
                    >
                      <ul className="menu-button">
                        <li className="header-button button-1">
                          <Link className="button-outline font-size-16" to="/menu">
                            Order Online
                          </Link>
                        </li>
                        {/* <li className="header-button button-2">
                          <Link
                            className="button font-size-16"
                            to="#"
                          >
                            Install Theme
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid content-wrapper">
            <div className="row-fluid-wrapper">
              <div className="row-fluid">
                <div
                  className="span12 widget-span widget-type-cell "
                  style={{}}
                  data-widget-type="cell"
                  data-x={0}
                  data-w={12}
                ></div>
              </div>
            </div>
          </div>
        </header>
      </div>
  )
}
