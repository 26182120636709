import React from "react";
import Articles from "../components/BlogPosts/Articles";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import PerfectHamburger from "../components/BlogPosts/PerfectHamburger";
import RelatedPosts from "../components/BlogPosts/RelatedPosts";
import Form from "../components/BlogPosts/Form";
import FreeRecipe from "../components/BlogPosts/FreeRecipe";
import Subscribe from "../components/BlogListing/Subscribe";

export default function BlogPosts() {
  return (
    <div className="body-wrapper   hs-content-id-83640319589 hs-blog-post hs-content-path-frosty-blog-the-secret-trick-to-prepare-a-perfect- hs-content-name-the-secret-trick-to-prepare-a-perfect-hamburger hs-blog-name-frosty-blog hs-blog-id-83644642232">
      <Header />
      <main
        id="main-content"
        class="body-container-wrapper"
        style={{ marginBottom: "40px" }}
      >
        <div class="container-fluid body-container body-container--blog-post">
          <div className="row-fluid-wrapper">
            <div className="row-fluid">
              <div
                className="span12 widget-span widget-type-cell "
                style={{}}
                data-widget-type="cell"
                data-x={0}
                data-w={12}
              >
                <Articles />
                <PerfectHamburger />
                <Form />
                <RelatedPosts />
                <FreeRecipe />
                <Subscribe />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
