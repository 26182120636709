import React from "react";
import { Link } from "react-router-dom";

export default function AboutUs() {
  return (
    <div className="row-fluid-wrapper row-depth-1 row-number-8 dnd-section dnd_area-row-3-background-color">
      <div className="row-fluid ">
        <div
          className="span12 widget-span widget-type-cell dnd-column"
          style={{}}
          data-widget-type="cell"
          data-x={0}
          data-w={12}
        >
          <div className="row-fluid-wrapper row-depth-1 row-number-9 dnd-row">
            <div className="row-fluid ">
              <div
                className="span12 widget-span widget-type-custom_widget dnd-module"
                style={{}}
                data-widget-type="custom_widget"
                data-x={0}
                data-w={12}
              >
                <div
                  id="hs_cos_wrapper_widget_1662017146177"
                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                  style={{}}
                  data-hs-cos-general-type="widget"
                  data-hs-cos-type="module"
                >
                  <section className="frosty-hero-1 ">
                    <div className="flex flex--left-image">
                      <div className="col">
                        <img
                          className="img-block border-radius"
                          src="https://res.cloudinary.com/diyncva2v/image/upload/v1710618937/kawlymxagqtzmcjenoqw.jpg"
                          alt="WahFood"
                          loading="lazy"
                          style={{
                            maxWidth: "100%",
                            height: "auto",
                          }}
                        />
                      </div>
                      <div className="col">
                        <div className="heading-text">
                          <h2>
                            <span className="heading-type heading-color font-size-0">
                              About Us
                            </span>
                          </h2>
                        </div>
                        <div className="mb-40">
                          <p>
                            We are India's one of the fastest growing QSR brands.
                            Serving delicious & unique varieties of puchkas & authentic
                            litti chokha to our beloved customers to keep them happy with
                            the touch of Desi-ness. What makes us di erent is our focus
                            on quality & hygiene at a ordable prices.
                            <br />
                            <br />
                            Our journey started from the "City of Joy" in 2023,
                            the name of our parent company is Unifood Delights
                            Private Limited.
                            <br />
                            <br />
                            We believe the magic of handmade snacks so as we do 
                            from our centralised kitchen to our QSRs for dine-in or 
                            to the doorstep of yours. Yes, our puchkas & littis are 
                            also available on Zomato & Swiggy.
                            <br />
                            <br />
                            Have a happily healthy snacking !!
                          </p>
                        </div>
                        <div className="button-wrap">
                          <div className="button-1 button-col">
                            <Link className="button" to="/contact">
                            Ask anything
                            </Link>
                          </div>
                          <div className="button-2 button-col">
                            <a className="button-outline" href="https://docs.google.com/forms/d/1rh7AEfi_murHZvruQU9QU9vGRAUBsi9Em_AIGNJp2Vk/viewform" target="_blank">
                            Join team Wah!
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
