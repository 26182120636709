import React, { useEffect, useState, useRef } from "react";

export default function Items({ products }) {

 
  useEffect(() => {
    // Calculate and set the height of each product section
    const setProductSectionHeight = () => {
      const productSections = document.querySelectorAll(".product-section");

      productSections.forEach((section) => {
        const contentHeight = section.querySelector(".product-content").clientHeight;
        section.style.height = `${contentHeight}px`;
      });
    };

    // Call the function once after the initial render and whenever the window is resized
    setProductSectionHeight();
    window.addEventListener("resize", setProductSectionHeight);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", setProductSectionHeight);
    };
  }, []);


  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  return (
    <div className="row-fluid-wrapper row-depth-1 row-number-1 dnd-section">
      <div className="row-fluid ">
        <div
          className="span12 widget-span widget-type-cell dnd-column"
          style={{}}
          data-widget-type="cell"
          data-x={0}
          data-w={12}
        >
          <div className="row-fluid-wrapper row-depth-1 row-number-2 dnd-row">
            <div className="row-fluid ">
              <div
                className="span12 widget-span widget-type-custom_widget dnd-module"
                style={{}}
                data-widget-type="custom_widget"
                data-x={0}
                data-w={12}
              >
                <div
                  id="hs_cos_wrapper_widget_1663068668186"
                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                  style={{}}
                  data-hs-cos-general-type="widget"
                  data-hs-cos-type="module"
                >
                  <section className="frosty-gallery-filter text-center">
                    <div id="users-widget_1663068668186">
                      <ul
                        className="list macy-container"
                        id="popup-gallery-widget_1663068668186"
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "flex-start",
                          listStyle: "none",
                          padding: 0,
                          margin: 0,
                          ...(window.innerWidth <= 600 && {
                            flexDirection: "column",
                            alignItems: "center", // Center items when stacked vertically
                          }),
                        }}
                      >
                        {products.map((product) => (
                          <li
                            className="list--list-item border-radius box-shadow"
                            data-gallery="maindishes"
                            style={{
                              flex: "0 0 calc(33.333% - 20px)",
                              marginBottom: 20,
                              marginRight: isMobile ? 0 : 20,
                              boxSizing: "border-box",
                            }}
                            key={product.id}
                          >
                            <div
                              ref={(el) => (el && (el.style.height = "auto"))}
                              className="product-section"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <img
                                className="border-radius"
                                src={product.image_url}
                                alt={product.food_name}
                                loading="lazy"
                                style={{ maxWidth: "100%", height: "auto" }}
                              />
                              <div className="product-content">
                                <div
                                  className="gallery-title mt-30"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <h2
                                    className="font-size-21"
                                    style={{
                                      margin: 0,
                                      width: "63%",
                                      textAlign: "start",
                                      marginLeft: "20px",
                                    }}
                                  >
                                    {product.food_name}
                                  </h2>
                                  <h2
                                    className="font-size-21"
                                    style={{ margin: 0, marginRight: "20px" }}
                                  >
                                    ₹ {product.price}/-
                                  </h2>
                                </div>
                                <div className="gallery-title p-20">
                                  <p>{product?.discerption}</p>
                                </div>
                                <div className="gallery-title p-20">
                                  <p>Order from your favorite app today!</p>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "space-around",
                                    }}
                                  >
                                    <a
                                      href={product.swiggy_url}
                                      style={{
                                        marginLeft: "10px",
                                        marginRight: "10px",
                                        courser: "pointer",
                                      }}
                                      target="_blank"
                                    >
                                      <img
                                        src="https://res.cloudinary.com/diyncva2v/image/upload/v1709450170/trm0soxfn7qb8ijcu7xl.png"
                                        alt="placeholder"
                                      />
                                    </a>
                                    <a
                                      href={product.zomato_url}
                                      style={{
                                        marginLeft: "10px",
                                        marginRight: "10px",
                                        courser: "pointer",
                                      }}
                                      target="_blank"
                                    >
                                      <img
                                        src="https://res.cloudinary.com/diyncva2v/image/upload/v1709450171/ymyzlhjawzqpdrk5q90p.png"
                                        alt="placeholder"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
