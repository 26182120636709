import React from "react";

export default function BlogCards() {
  return (
    <div className="row-fluid-wrapper row-depth-1 row-number-2 dnd-section">
      <div className="row-fluid ">
        <div
          className="span12 widget-span widget-type-cell dnd-column"
          style={{}}
          data-widget-type="cell"
          data-x={0}
          data-w={12}
        >
          <div className="row-fluid-wrapper row-depth-1 row-number-3 dnd-row">
            <div className="row-fluid ">
              <div
                className="span12 widget-span widget-type-custom_widget dnd-module"
                style={{}}
                data-widget-type="custom_widget"
                data-x={0}
                data-w={12}
              >
                <div
                  id="hs_cos_wrapper_dnd_area-module-3"
                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                  style={{}}
                  data-hs-cos-general-type="widget"
                  data-hs-cos-type="module"
                >
                  <section className="frosty-blog-listings-2">
                    <div className="heading-flex">
                      <div className="heading-col">
                        <div className="heading-text">
                          <h2>
                            <span className="heading-type heading-color font-size-">
                              Latest Posts
                            </span>
                          </h2>
                        </div>
                      </div>
                      <div className="heading-col text-right text-phone-left">
                        <ul className="filter-list list-inline letter-spacing-1 font-size-16 font-weight-600">
                          <li className="list-inline-item">Filter by:</li>
                          <li className="list-inline-item">
                            <a
                              className="px-10 py-5 font-size-16 button-outline"
                              href="blog/all.html"
                            >
                              All
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a
                              className="px-10 py-5 font-size-16 "
                              href="blog/tag/recipes.html"
                            >
                              Recipes
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a
                              className="px-10 py-5 font-size-16 "
                              href="blog/tag/articles.html"
                            >
                              Articles
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="col">
                        <div className="blog-card border-radius text-center">
                          <div className="blog-card-featured-image text-center">
                            <a href="blog/5-great-burger-restaurants-in-california.html">
                              <img
                                src="https://info.juicetactics.com/hubfs/pexels-valeria-boltneva-1639557.jpg"
                                className="featured-image"
                                alt="Best burgers"
                              />
                            </a>
                          </div>
                          <div className="blog-card-body pt-30 pb-40 px-40">
                            <div className="blog-card-topic mb-10">
                              <a
                                className="button px-10 py-5 font-size-14"
                                href="blog/tag/recipes.html"
                              >
                                Recipes
                              </a>
                            </div>
                            <ul className="list-inline author-date mt-20">
                              <li className="list-inline-item font-size-14">
                                <a href="blog/author/frosty.html" rel="author">
                                  Frosty
                                </a>
                              </li>
                              <li className="list-inline-item blog-card-date font-size-14">
                                September 1, 2022
                              </li>
                            </ul>
                            <div className="blog-post-name mb-10">
                              <h3 className="font-size-24 mb-0">
                                <a href="blog/5-great-burger-restaurants-in-california.html">
                                  5 great burger restaurants in California
                                </a>
                              </h3>
                            </div>
                            <div className="blog-card-text">
                              What does the perfect hamburger taste like? Lorem
                              ipsum dolor sit amet, consectetur adipiscing elit
                              lobortis arcu enim...
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="blog-card border-radius text-center">
                          <div className="blog-card-featured-image text-center">
                            <a href="blog/how-to-prepare-buttermilk-fried-chicken-tenders-0.html">
                              <img
                                src="https://info.juicetactics.com/hubfs/pexels-valeria-boltneva-1639557.jpg"
                                className="featured-image"
                                alt="delicious cheesecake"
                              />
                            </a>
                          </div>
                          <div className="blog-card-body pt-30 pb-40 px-40">
                            <div className="blog-card-topic mb-10">
                              <a
                                className="button px-10 py-5 font-size-14"
                                href="blog/tag/recipes.html"
                              >
                                Recipes
                              </a>
                            </div>
                            <ul className="list-inline author-date mt-20">
                              <li className="list-inline-item font-size-14">
                                <a href="blog/author/frosty.html" rel="author">
                                  Frosty
                                </a>
                              </li>
                              <li className="list-inline-item blog-card-date font-size-14">
                                September 1, 2022
                              </li>
                            </ul>
                            <div className="blog-post-name mb-10">
                              <h3 className="font-size-24 mb-0">
                                <a href="blog/how-to-prepare-buttermilk-fried-chicken-tenders-0.html">
                                  5 delicious cheesecake recipes you can prepare
                                </a>
                              </h3>
                            </div>
                            <div className="blog-card-text">
                              What does the perfect hamburger taste like? Lorem
                              ipsum dolor sit amet, consectetur adipiscing elit
                              lobortis arcu enim...
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="blog-card border-radius text-center">
                          <div className="blog-card-featured-image text-center">
                            <a href="blog/how-to-prepare-buttermilk-fried-chicken-tenders.html">
                              <img
                                src="https://info.juicetactics.com/hubfs/pexels-valeria-boltneva-1639557.jpg"
                                className="featured-image"
                                alt="Buttermilk Fried Chicken Tenders"
                              />
                            </a>
                          </div>
                          <div className="blog-card-body pt-30 pb-40 px-40">
                            <div className="blog-card-topic mb-10">
                              <a
                                className="button px-10 py-5 font-size-14"
                                href="blog/tag/recipes.html"
                              >
                                Recipes
                              </a>
                            </div>
                            <ul className="list-inline author-date mt-20">
                              <li className="list-inline-item font-size-14">
                                <a href="blog/author/frosty.html" rel="author">
                                  Frosty
                                </a>
                              </li>
                              <li className="list-inline-item blog-card-date font-size-14">
                                September 1, 2022
                              </li>
                            </ul>
                            <div className="blog-post-name mb-10">
                              <h3 className="font-size-24 mb-0">
                                <a href="blog/how-to-prepare-buttermilk-fried-chicken-tenders.html">
                                  How to prepare buttermilk fried chicken
                                  tenders
                                </a>
                              </h3>
                            </div>
                            <div className="blog-card-text">
                              What does the perfect hamburger taste like? Lorem
                              ipsum dolor sit amet, consectetur adipiscing elit
                              lobortis arcu enim...
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="blog-card border-radius text-center">
                          <div className="blog-card-featured-image text-center">
                            <a href="blog/4-easy-recipes-to-prepare-a-delicious-homemade-pancakes.html">
                              <img
                                src="https://info.juicetactics.com/hubfs/pexels-valeria-boltneva-1639557.jpg"
                                className="featured-image"
                                alt
                              />
                            </a>
                          </div>
                          <div className="blog-card-body pt-30 pb-40 px-40">
                            <div className="blog-card-topic mb-10">
                              <a
                                className="button px-10 py-5 font-size-14"
                                href="blog/tag/recipes.html"
                              >
                                Recipes
                              </a>
                            </div>
                            <ul className="list-inline author-date mt-20">
                              <li className="list-inline-item font-size-14">
                                <a href="blog/author/frosty.html" rel="author">
                                  Frosty
                                </a>
                              </li>
                              <li className="list-inline-item blog-card-date font-size-14">
                                September 1, 2022
                              </li>
                            </ul>
                            <div className="blog-post-name mb-10">
                              <h3 className="font-size-24 mb-0">
                                <a href="blog/4-easy-recipes-to-prepare-a-delicious-homemade-pancakes.html">
                                  4 easy recipes to prepare a delicious pancakes
                                </a>
                              </h3>
                            </div>
                            <div className="blog-card-text">
                              What does the perfect hamburger taste like? Lorem
                              ipsum dolor sit amet, consectetur adipiscing elit
                              lobortis arcu enim...
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="blog-card border-radius text-center">
                          <div className="blog-card-featured-image text-center">
                            <a href="blog/the-secret-trick-to-prepare-a-perfect-hamburger.html">
                              <img
                                src="https://info.juicetactics.com/hubfs/pexels-valeria-boltneva-1639557.jpg"
                                className="featured-image"
                                alt
                              />
                            </a>
                          </div>
                          <div className="blog-card-body pt-30 pb-40 px-40">
                            <div className="blog-card-topic mb-10">
                              <a
                                className="button px-10 py-5 font-size-14"
                                href="blog/tag/articles.html"
                              >
                                Articles
                              </a>
                            </div>
                            <ul className="list-inline author-date mt-20">
                              <li className="list-inline-item font-size-14">
                                <a href="blog/author/frosty.html" rel="author">
                                  Frosty
                                </a>
                              </li>
                              <li className="list-inline-item blog-card-date font-size-14">
                                September 1, 2022
                              </li>
                            </ul>
                            <div className="blog-post-name mb-10">
                              <h3 className="font-size-24 mb-0">
                                <a href="blog/the-secret-trick-to-prepare-a-perfect-hamburger.html">
                                  The secret trick to prepare a perfect
                                  hamburger
                                </a>
                              </h3>
                            </div>
                            <div className="blog-card-text">
                              What does the perfect hamburger taste like? Lorem
                              ipsum dolor sit amet, consectetur adipiscing elit
                              lobortis arcu enim...
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="blog-card border-radius text-center">
                          <div className="blog-card-featured-image text-center">
                            <a href="blog/how-to-prepare-the-perfect-french-fries.html">
                              <img
                                src="https://info.juicetactics.com/hubfs/pexels-valeria-boltneva-1639557.jpg"
                                className="featured-image"
                                alt
                              />
                            </a>
                          </div>
                          <div className="blog-card-body pt-30 pb-40 px-40">
                            <div className="blog-card-topic mb-10">
                              <a
                                className="button px-10 py-5 font-size-14"
                                href="blog/tag/articles.html"
                              >
                                Articles
                              </a>
                            </div>
                            <ul className="list-inline author-date mt-20">
                              <li className="list-inline-item font-size-14">
                                <a href="blog/author/frosty.html" rel="author">
                                  Frosty
                                </a>
                              </li>
                              <li className="list-inline-item blog-card-date font-size-14">
                                October 20, 2021
                              </li>
                            </ul>
                            <div className="blog-post-name mb-10">
                              <h3 className="font-size-24 mb-0">
                                <a href="blog/how-to-prepare-the-perfect-french-fries.html">
                                  How to prepare the perfect french fries?
                                </a>
                              </h3>
                            </div>
                            <div className="blog-card-text">
                              What does the perfect hamburger taste like? Lorem
                              ipsum dolor sit amet, consectetur adipiscing elit
                              lobortis arcu enim...
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              {/*end widget-span */}
            </div>
            {/*end row*/}
          </div>
          {/*end row-wrapper */}
        </div>
        {/*end widget-span */}
      </div>
      {/*end row*/}
    </div>
  );
}
