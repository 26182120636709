import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function Story() {
    const slides = [
        {
            imageUrl: 'https://res.cloudinary.com/diyncva2v/image/upload/v1710618937/myip0k5lkbybdzqvui9o.jpg',
            heading: 'Our Misson',
            description: 'We are on a mission to serve people the best flavoured Puchka & Litti inside a peaceful comfortable & hygienic environment so they can have a pleasure guilt free snacking whenever & wherever they want , we have fine Dine-Ins & available online in Swiggy/Zomato. We have varieties of Puchka & Litti chokha flavours & great customisation as per customer’s need.',
        },
        {
            imageUrl: 'https://res.cloudinary.com/diyncva2v/image/upload/v1710618937/llozkln9wuxveaff59rb.jpg',
            heading: 'Our Vision',
            description: "India is a fast changing, fast paced and fast developing nation and we at Wah! want to standardise Indian Snacking habits. We aim to touch various cultures with our fusion Puchka & Littis, by providing people a joyous, healthier, hygienic Puchka & Littis under one roof followed by a mesmerising ambiance & sweet gesture. Our prospective is to be in QSR models & we would love to get in touch with colleges & corporate canteens, complexes etc. We are proud to claim ourselves as India's first fusion Puchka brand to drape the cultural diversity in our innovative fusions. Our expansion mode is aggressively on in other cities like Bangalore,Delhi etc.",
        },
      
    ];

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true, // Auto-scroll
        autoplaySpeed: 8000, // Auto-scroll speed in milliseconds
    };
    return (
        <Slider {...sliderSettings}>
            {slides.map((slide, index) => (
                <section key={index} className="frosty-hero-1">
                    <div className="flex flex--left-image">
                        <div className="col">
                            <img
                                className="img-block border-radius"
                                src={slide.imageUrl}
                                alt={`slide-${index}`}
                                loading="lazy"
                                style={{
                                    maxWidth: '100%',
                                    height: 'auto',
                                    border: '1px solid rgba(0, 0, 0, 0.1)', 
                                    borderRadius: '15px', /* Optional: Add some rounding for a softer look */
                                    transition: 'box-shadow 0.3s ease', /* Optional: Add transition for hover effects */
                                  }}
                            />
                        </div>
                        <div className="col">
                            <div className="heading-text">
                                <h2>
                                    <span className="heading-type heading-color font-size-0">{slide.heading}</span>
                                </h2>
                            </div>
                            <div className="mb-40">
                                <p>{slide.description}</p>
                            </div>
                            
                        </div>
                    </div>
                </section>
            ))}
        </Slider>
    );
}
