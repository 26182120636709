import React, { useEffect, useState } from "react";
import Header from "../Layout/Header";
import OurMenu from "../components/Menu/OurMenu";
// import TeamStory from "../components/Menu/TeamStory";
import Footer from "../Layout/Footer";
import Items from "../components/Menu/Items";
import axios from "axios";
import BounceLoader from "react-spinners/BounceLoader";

const API_URL = "https://script.googleusercontent.com/macros/echo?user_content_key=pWh5c0T6MGvRhFAQPy8e7wsQuPSaxUJIRb84ahmn3M_Qh85fRKt5Gnl8NTQAllEbst3iNxLEgBgc3ysqu8hjXRRGDwusM2ism5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnKfQFJrxQNXqz_hfmCuxp__vXh_dn2zgv728EPFVsdDO5lbsuzCeXjX-cSr8OSCFBmAi1TpL04ipVsA0BIGe_lEs5clFe3_t59z9Jw9Md8uu&lib=MAK88FHEYXBbJikQUAVbKZqxtnBi4VF6q"



export default function Menu() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [filterProducts, setFilterProducts] = useState([]);

  const getProduct = async () => {
    try {
      // Check if products and categories exist in local storage
      const storedProducts = localStorage.getItem('productss');
      const storedCategories = localStorage.getItem('categoriess');
      if (storedProducts && storedCategories) {
        setProducts(JSON.parse(storedProducts));
        setCategories(JSON.parse(storedCategories));
        setLoading(false);
        return; // Exit the function if data is found in local storage
      }
  
      const response = await axios.get(API_URL);
      if (response.status === 200) {
        const fetchedProducts = response.data.data;
        localStorage.setItem('products', JSON.stringify(fetchedProducts));
        setProducts(fetchedProducts);
  
        // Extract unique categories and set them in state
        const uniqueCategories = [...new Set(fetchedProducts.map(item => item.category))];
        setCategories(uniqueCategories);
        localStorage.setItem('categories', JSON.stringify(uniqueCategories));
  
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }
  

  const filterProductsByCategory = (category) => {
    if (category === 'All') {
      setFilterProducts([]);
      getProduct();
    }
    const cat = products.filter(item => item.category === category);
    setFilterProducts(cat);
  };

  useEffect(() => {
    getProduct();
  }, [products, categories]);


  return (
    <div className="body-wrapper   hs-content-id-84769436546 hs-site-page page hs-content-path-frosty-menu hs-content-name-frosty-menu  ">
      <Header />
      {
        loading ? (
          <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <BounceLoader color="#E85900" />
          </div>
        ) : (
          <main id="main-content" className="body-container-wrapper">
            <div className="container-fluid body-container body-container--about container-fluid-custom">
              <div className="row-fluid-wrapper">
                <div className="row-fluid">
                  <div
                    className="span12 widget-span widget-type-cell "
                    style={{}}
                    data-widget-type="cell"
                    data-x={0}
                    data-w={12}
                  >
                    <OurMenu categories={categories} filterProductsByCategory={filterProductsByCategory} />
                    {
                      filterProducts.length > 0 ?                 
                        <Items products={filterProducts} /> 
                        : 
                        <Items products={products} />
                    }
                    
                  </div>
                </div>
              </div>
            </div>
          </main>
        )
      }
      <Footer />
    </div>
  );
}
